import * as React from 'react'
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom'
import { Paper, Toolbar, Tabs, Tab } from '@material-ui/core'
import {
  Grid,
  PostList,
  LessonList,
  ProgressCard,
  FilterDialog,
  SettingsMenu,
  AchievementsList,
  ChallengesList,
  Loader, AdminSettingsMenu,
} from '../../components'
import {VideoForm, LessonForm, ContestantForm, UserPrivileges} from '../../forms'
import { useAuth } from '../../state-management'
import { getData } from '../../state-management/api'
import { User, Sport } from '../../state-management/types'
import {isPrivileged} from '../../utils';
import './styles.scss'

interface UserParamType {
  username: string
}

interface UserProps {
  previous: {
    location: string
  }
}

const UserPage: React.FC<UserProps & RouteComponentProps> = ({
  history,
}) => {
  const { state, getSports } = useAuth()

  const { username } = useParams<UserParamType>()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<number>(0)
  const [user, setUser] = React.useState<User>()
  
  React.useEffect(() => {
    const usernameQuery = username || state.logname

    setLoading(true)
    getData(`users/?username=${usernameQuery}`)
      .then(({ results, count }) => {
        if (count > 0) setUser(results[0])
        setLoading(false)
      })
      .catch(({ response }) => {
        setLoading(false)
        return response
      })
  }, [username, state.user, state.logname]);

  React.useEffect(() => {
    getSports().catch((error) => {
      console.log(error)
    })
  }, []) // eslint-disable-line

  if (!state.isAuthenticated) history.push('/login')
  
  let component = null

  switch (value) {
    case 0:
      component = <PostList user={user} sport={state.sport} />
      break

    case 1:
      component = (
        <>
          {state.sport ? (
            <AchievementsList user={user} sport={state.sport} />
          ) : (
            <>
              {state.sports?.map((sport: Sport) => (
                <>
                  <div className="one-sport-box">
                    <div className="sport-name">
                      {sport.name}
                    </div>
                    <AchievementsList user={user} sport={sport} />
                  </div>
                </>
              ))}
            </>
          )}
        </>
      )
      break

    case 2:
      component = (
        <>
          {state.sport ? (
            <ChallengesList sport={state.sport} />
          ) : (
            <>
              {state.sports?.map((sport: Sport) => (
                <>
                  <div className="one-sport-box">
                    <div className="sport-name">
                      {sport.name}
                    </div>
                    <ChallengesList sport={sport} />
                  </div>
                </>
              ))}
            </>
          )}
        </>
      )
      break

    case 3:
      component = <LessonList user={user} sport={state.sport} />
      break

    default:
      console.log('Error in scoreboard tab value')
      break
  }
  
  
  let uploadForm = <ContestantForm user={user} />
  if (user?.contestant_data?.id)
    uploadForm = <VideoForm open={false} user={user}/>
  
  if (state.user?.id !== user?.id)
    if (!isPrivileged(state.user))
      uploadForm = <></>
    else if (user?.contestant_data?.id)
      uploadForm = <LessonForm user={user} />
  
  const userComponent = (
    <div className='lake-games--user'>
      <div className='lake-games--user-header'>
        <div className='lg-user--title'>
          <div className='lg-user--name'>{username}</div>
          {user && isPrivileged(state.user) ? <AdminSettingsMenu user={user} /> : null}
          {user?.id === state.user?.id ? <SettingsMenu /> : null}
        </div>
        {loading ? <Loader loading={loading}/> : <>{uploadForm}</>}

      </div>

      {user?.contestant_data ? (
        <>
          <ProgressCard progressColor='auto' user={user} sport={state.sport || undefined}/>
          <FilterDialog sport />
          <br/><br/>
          <Toolbar className='tool__bar'>
            <Paper className='user-paper'>
              <Tabs
                value={value}
                indicatorColor='primary'
                textColor='primary'
                onChange={(e, newVal) => setValue(newVal)}
                aria-label='user-tabs'
                className='user-tabs'
              >
                <Tab label='Posts' />
                <Tab label='Achievements' />
                <Tab label='Challenges' />
                {user?.id === state?.user?.id || isPrivileged(state.user) ? <Tab label='Lessons' /> : null}
              </Tabs>
            </Paper>
          </Toolbar>
          {component}
        </>
      ) : (
        <>
          {user?.id === state.user?.id ? (
            <>Create a contestant account to start posting videos!</>
          ) : null}
        </>
      )}
    </div>
  )

  return (
    // @ts-ignore
    <Grid
      items={[
        {
          id: 'lake-games--user',
          cols: { xs: 12, sm: 8, md: 6, lg: 6 },
          content: userComponent,
          rows: 1,
        },
      ]}
    />
  )
}

export default withRouter(UserPage)
