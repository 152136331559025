import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth } from '../state-management';
import {
  Login,
  Signup,
  Welcome,
  Forgot,
  Support,
  Rules,
  Home,
  Lakes,
  Search,
  Contest,
  Scoreboard,
  User,
} from '../pages';

interface PrivateRouteProps {
  component: any;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  path,
}) => {
  const { state } = useAuth();

  const isAuth = state.isAuthenticated;

  return (
    <Route
      render={(props) =>
        isAuth ? (
          <Component path={path} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { previous: props.location },
            }}
          />
        )
      }
    />
  );
};

const BaseRouter = (): JSX.Element => (
  <Switch>
    <Route path='/welcome' component={Welcome} />
    <Route path='/home' component={Home} />
    <Route path='/rules' component={Rules} />
    <Route path='/login' component={Login} />
    <Route path='/signup' component={Signup} />
    <Route path='/forgot' component={Forgot} />
    <Route path='/lakes' component={Lakes} />
    <Route path='/user/:username' component={User} />
    <Route path='/user' component={User} />
    
    <PrivateRoute path='/contest' component={Contest} />
    <PrivateRoute path='/search' component={Search} />
    <PrivateRoute path='/support' component={Support} />
    <PrivateRoute path='/leaderboards' component={Scoreboard} />
    {/* <PrivateRoute path='/user/:username' component={User} /> */}
    
    <Route path='/' component={Welcome} />
  </Switch>
);

export default BaseRouter;
