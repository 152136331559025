import * as React from 'react';
import { useAlert } from 'react-alert';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import { useAuth } from '../../state-management';
import { Post } from '../../state-management/types';
import { Message, Loader, TextField } from '../../components';
import './styles.scss';

interface CommentFormProps {
  isCoach: boolean;
  post?: Post;
}

const CommentForm: React.FC<CommentFormProps> = ({ isCoach, post }) => {
  const { state, uploadComment } = useAuth();
  
  const alert = useAlert()
  
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [msg, setMsg] = React.useState<string>('');
  const [commentText, setCommentText] = React.useState<string>('');

  const handleSubmit = () => {
    if (post && state.user)
      uploadComment(post, state.user, commentText).then((res: any) => {
        if (res?.status === 201) {
          const msgText = 'Successfully submitted feedback'
          setMsg(msgText);
          setTimeout(() => {
            setOpen(false)
            alert.show(msgText, { type: 'success' })
          }, 1000)
        }
        setLoading(false);
      });
  };

  const handleClick = () => {
    setOpen(!open);
    setCommentText('');
    setMsg('');
    setLoading(false);
  };

  let title = 'Ask the coach'
  let msgBackup = 'This will only be visible to coaches and staff members'
  if (isCoach) {
    title = 'Submit Feedback'
    msgBackup = 'Feedback is only visible to this user and other staff members'
  }
  return (
    <div>
      <div
        onClick={handleClick}
        aria-controls='simple-menu'
        aria-haspopup='true'
      >
        {title}
      </div>
      
      <Dialog
        fullWidth
        open={open}
        onClose={handleClick}
        aria-labelledby='comment-title'
        className='lg-comment-form'
      >
        <DialogTitle
          id='comment-dialog'
          className='lg-comment-form--title'
          disableTypography
        >
          Coaches Feedback
        </DialogTitle>

        <DialogContent className='lg-comment-form--content'>
          <Message severity='error' message={state.error} />
          <Message
            severity={msg ? 'success' : 'info'}
            message={
              msg || msgBackup
            }
          />

          <div>
            <TextField
              value={commentText}
              id='commentText'
              label='Comment'
              onChange={(e) => setCommentText(e.target.value)}
              handleSubmit={handleSubmit}
            />
          </div>
        </DialogContent>

        <DialogActions className='lg-comment-form--actions'>
          <Loader loading={loading} />
          <Button
            onClick={handleSubmit}
            color='primary'
            className='lg-comment-form--btn'
            classes={{
              label: 'lg-comment-form--btn-label',
            }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClick}
            color='primary'
            className='lg-comment-form--btn'
            classes={{
              label: 'lg-comment-form--btn-label',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommentForm;
