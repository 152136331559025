import * as React from 'react';
import { useAuth } from '../../state-management';
import {Division, Lake} from '../../state-management/types';
import {ScoreboardCard} from '..';
import './styles.scss';


const LakesScoreboard: React.FC = () => {
  const { state, getLakes } = useAuth();

  React.useEffect(() => {
    getLakes(state.community, 'score').catch((err) => console.log(err))
  }, [state.community]); // eslint-disable-line
  
  const getScore = (lake: Lake, division?: Division) => {
    return division ? lake?.scores_data?.divisions[division?.id]?.points : lake?.scores_data?.total_score
  }
  
  console.log('LAKES', state.lakes)
  
  const getRows = (division?: Division) => {
    return state.lakes.filter(lake => getScore(lake, division) > 0).map((lake: Lake) => (
      {
        id: lake.id,
        name: lake.name,
        score: getScore(lake, division)
      }
    ))
  }
  
  return (
    <>
      <ScoreboardCard rows={getRows()}/>
      {state.divisions?.map((division: Division) => (
        <div key={division.id}>
          <ScoreboardCard division={division} rows={getRows(division)}/>
        </div>
      ))}
    </>
  );
};

export default LakesScoreboard;
