import { useContext } from 'react';
import {UploadsContext, UploadsState} from './context';

export interface UseUploads {
  (): {
    uploadFile: (userId: number, uploadId: number, file: File, uploadUrl: string, chunkSize?: number ) => Promise<any>;
    state: UploadsState;
  };
}

export const useUploads: UseUploads = () => {
  const { state, dispatch } = useContext(UploadsContext);
  
  const defaultChunkSize = 5 * 1024 * 1024
  
  const uploadFile = async (userId: number, uploadId: number, file: File, uploadUrl: string, chunkSize?: number) => {
    const uploadChunkSize = chunkSize || defaultChunkSize
    await dispatch({ type: 'START_UPLOAD', userId, uploadId, uploadUrl, chunkSize: uploadChunkSize, file });
    
    const chunks = chunkFile(file, uploadChunkSize)
    
    for (let i = 0; i < chunks.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await uploadPart(uploadId, uploadUrl, i, chunks[i], uploadChunkSize, file.size)
    }
    
    dispatch({ type: 'FINISH_UPLOAD', uploadId })
    return Promise.resolve({ status: 201})
  }
  
  const chunkFile = (file: File, chunkSize: number) => {
    const chunks = [];
    const numChunks = Math.ceil(file.size / chunkSize);
    
    for(let i = 0; i < numChunks; i += 1) {
      const start = i * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      chunks.push(file.slice(start, end));
    }
    
    return chunks;
  }
  
  const uploadPart = (uploadId: number, uploadUrl: string, partNumber: number, chunk: Blob, chunkSize: number, fileSize: number) => {
    return new Promise((resolve, reject) => {
      
      const totalSentSize = Number(partNumber) * chunkSize
      const endChunk = totalSentSize + chunk.size - 1
      
      const xhr = new XMLHttpRequest()
      
      xhr.open('PUT', uploadUrl, true)
      
      xhr.setRequestHeader('Content-Range', `bytes ${totalSentSize}-${endChunk}/${fileSize}`);
      xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      
      xhr.onreadystatechange = () => {
        const isReady = xhr.readyState === 4
        const statusOK = xhr.status === 200 || xhr.status === 308 || xhr.status === 0
        
        if (isReady && statusOK)
          resolve(xhr.status)
      }
      
      xhr.upload.onprogress = (progress) => {
        const sent = Math.min(totalSentSize + progress.loaded, fileSize)
        const percentage = Math.round((sent / fileSize) * 100)
        dispatch({ type: 'SET_PROGRESS', uploadId, progress: percentage })
      }
      
      xhr.upload.onabort = () => {
        reject(new Error('Upload canceled by user'))
        dispatch({ type: 'CANCEL_UPLOAD', uploadId })
      }
      
      xhr.upload.onerror = (error) => {
        console.log(error)
        reject(error)
        dispatch({ type: 'ERROR_UPLOAD', error: 'Error uploading video', uploadId })
      }
      
      xhr.send(chunk)
    })
    
  }
  
  return {
    uploadFile,
    state,
  };
};
