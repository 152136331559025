import React, {
  useReducer,
  Reducer,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { reducer, UploadsAction } from './reducer';
import {
  UploadsContextState,
  defaultState,
  UploadsContext,
  UploadsState,
} from './context';

export type UploadsProviderI = (props: { children: ReactNode }) => JSX.Element;

export const UploadsProvider: UploadsProviderI = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<UploadsState, UploadsAction>>(
    reducer,
    defaultState()
  );

  const [contextValue, setContextValue] = useState<UploadsContextState>({
    state,
    dispatch,
  });

  useEffect(() => {
    setContextValue((newContextValue) => ({
      ...newContextValue,
      state,
    }));
  }, [state]);

  return (
    <UploadsContext.Provider value={contextValue}>{children}</UploadsContext.Provider>
  );
};
