import * as React from 'react'
import { useAlert } from 'react-alert';
import {Button, Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import {useAuth} from '../../state-management'
import {ContestantEntry, Division} from '../../state-management/types'
import {
  Message,
  Loader,
  SelectField,
} from '../../components'
import './styles.scss'

interface EditContestantEntryDivisionFormProps {
  contestantEntry?: ContestantEntry
}

const EditContestantEntryDivisionForm: React.FC<EditContestantEntryDivisionFormProps> = ({contestantEntry }) => {
  const {state, getDivisions, editContestantEntryDivision} = useAuth()
  
  const alert = useAlert()
  
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [msg, setMsg] = React.useState<string>('')
  
  const [division, setDivision] = React.useState<Division>()
  
  React.useEffect(() => {
    getDivisions()
  }, []) // eslint-disable-line
  
  const handleSubmit = () => {
    
    setLoading(true)
    setError('')
    editContestantEntryDivision(contestantEntry, division).then((res: any) => {
      if (res?.status === 200) {
        const msgText = 'Successfully updated division'
        setMsg(msgText)
        setTimeout(() => {
          handleClick()
          alert.show(msgText, { type: 'success' })
        }, 500)
      } else {
        setError(res.data?.detail)
      }
    })
  
  }
  
  const handleClick = () => {
    if (isOpen) clearForm()
    setIsOpen(!isOpen);
  };
  
  const clearForm = () => {
    setDivision(undefined)
    setMsg('')
    setError('')
    setLoading(false)
  }
  
  const title = 'Edit Contestant Division'
  const errorMsg = error || state.error
  
  return (
    <div className='video-form-dialog'>
      <div onClick={handleClick}>{title}</div>
      <Dialog
        onClose={handleClick}
        className='dialog-box'
        aria-labelledby='simple-dialog-title'
        open={isOpen}
        fullWidth
      >
        <DialogTitle
          disableTypography
          className='dialog-box--title'
          id='simple-dialog-title'
        >
          Edit Division
        </DialogTitle>
        <DialogContent className='dialog-box--content'>
          <div className='lg-video-form'>
            
            <SelectField
              label='Division'
              required
              value={division}
              values={state.divisions}
              setValue={setDivision}
            />
            
            <Message
              severity={errorMsg ? 'error' : 'success'}
              message={errorMsg || msg}
            />
            
            <Loader loading={loading}/>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleSubmit}
              className='lg-video-form--btn'
              classes={{
                label: 'lg-video-form--btn-label',
              }}
            >
              Submit
            </Button>
          </div>
          <br/>
          <Button
            className='dialog-box--btn'
            variant='outlined'
            color='primary'
            onClick={handleClick}
            classes={{label: 'dialog-box--btn-label'}}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default EditContestantEntryDivisionForm
