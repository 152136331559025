import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import {Stack} from '@mui/material';
import {AppBar, Button, Toolbar} from '@material-ui/core';

import { FilterDialog, PostList } from '../../components';
import { useAuth } from '../../state-management';
import './styles.scss';

const Home: React.FC<RouteComponentProps> = () => {
  const { state } = useAuth();
  
  const homeComponent = (
    <div className='lg-home'>
      <AppBar className='lg-home--actions'>
        <Toolbar className='lg-home--toolbar'>
          <Stack direction='row' spacing={2}>
            <FilterDialog lake division sport />
          </Stack>
          <Stack direction='row' spacing={2} alignContent='left'>
            <Button key='containedPrimary'>
              <Link className='btn btn--secondary' to='/contest' style={{ textDecoration: 'none', color: 'black' }}>
                <span className='btn__text'>Click here to view the Payton Power Classic!</span>
              </Link>
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <br/>
      <br/>
      <br/>
      <div className='lg-home--posts'>
        <PostList
          community={state.community}
          lake={state.lake}
          division={state.division}
          sport={state.sport}
        />
      </div>
    </div>
  );

  return (
    <> {homeComponent} </>
    // <Grid
    //   items={[
    //     {
    //       id: 'lg-home',
    //       cols: { xs: 12, sm: 8, md: 6, lg: 4 },
    //       content: homeComponent,
    //       rows: 1
    //     }
    //   ]}
    // />
  );
};

export default withRouter(Home);
