import * as React from 'react'
import { useAlert } from 'react-alert';
import {Button, Checkbox} from '@material-ui/core';
import {Divider} from '@mui/material'
import dayjs from 'dayjs'
import { useAuth } from '../../state-management'
import {Lake, Contestant, Contest, User, Division} from '../../state-management/types'
import {
  Message,
  Loader,
  SelectField,
  DialogBox,
  TextField ,
} from '../../components'
import './styles.scss'
import {contestantDisplayName, getError, isPrivileged} from '../../utils';

interface ContestEntryFormProps {
  contest: Contest
}

const ContestEntryForm: React.FC<ContestEntryFormProps> = ({ contest }) => {
  const { state, createContestEntry, getLakes, getUsers } = useAuth()
  
  const alert = useAlert()
  
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  
  const [userToRegister, setUserToRegister] = React.useState<User>()
  const [users, setUsers] = React.useState<User[]>([])
  const [registeredContestants, setRegisteredContestants] = React.useState<Contestant[]>([])
  
  const [registeringOthers, setRegisteringOthers] = React.useState<boolean>(false)
  const [firstName, setFirstName] = React.useState<string>(state.user?.first_name || '')
  const [lastName, setLastName] = React.useState<string>(state.user?.last_name || '')
  const [lake, setLake] = React.useState<Lake>()
  const [hometown, setHometown] = React.useState<string>(state.user?.contestant_data?.hometown || '')
  const [age, setAge] = React.useState<number>()
  const [division, setDivision] = React.useState<Division>()
  const [gender, setGender] = React.useState<string>('')
  const genders = ['Male', 'Female']

  React.useEffect(() => {
    getUsers().then((res) => {
      let existingContestantUserIds: number[] = []
      contest.divisions.forEach((division) => {
        division.contestant_entries.forEach((entry) => {
          existingContestantUserIds.push(entry.user_id)
        })
      })
      setUsers(res.filter((user) => !existingContestantUserIds.includes(user.id)))
    }).catch((e) => {
      console.log(e)
    })
    getLakes(null).catch((e) => {
      console.log(e)
    })
  }, []) // eslint-disable-line
  
  const handleSubmit = async () => {
    if (shouldShowForm()) {
      if (!firstName) {
        setError('Please enter first name')
        return
      }
      else if (!lastName) {
        setError('Please enter last name')
        return
      }
      else if (!hometown) {
        setError('Please enter hometown')
        return
      }
      else if (!division) {
        setError('Please enter division to compete in')
        return
      }
      else if (!age) {
        setError('Please enter current age')
        return
      }
      else if (!gender) {
        setError('Please choose gender to participate with')
        return
      }
    }
    
    setLoading(true)
    const user = userToRegister ?? state.user
    const res = await createContestEntry(!registeringOthers, contest, user, firstName, lastName, lake, hometown, division, gender)
    if (res?.status === 201) {
      setError('')
      const msgText = `Successfully registered contestant for the ${contest.name}`
      alert.show(msgText, { type: 'success' })
      
      const tmpContestants = registeredContestants
      tmpContestants.push(res.data)
      setRegisteredContestants(tmpContestants)
      
      setUsers(users.filter((u) => u.id !== user?.id))
      
      setFirstName('')
      setDivision(undefined)
      setGender('')
      setAge(undefined)
    } else {
      setError(getError(res?.data))
    }
    setLoading(false)
  }

  const errorMsg = error || state.error
  
  const shouldShowForm = () => (!registeringOthers && !state.user?.contestant_data) || (registeringOthers && (!userToRegister?.id || !userToRegister?.contestant_data))
  
  const handleCheck = () => {
    setRegisteringOthers(!registeringOthers)
    if (!registeringOthers) {
      setFirstName('')
      setLastName('')
      setHometown('')
      setGender('')
      setUserToRegister({} as User)
      setDivision(undefined)
      setLake(undefined)
    }
  };
  
  return (
    <div>
      <DialogBox id='contest-entry-dialog' title='Click here to register'>
        <p>
          <Checkbox
            value={registeringOthers}
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={handleCheck}
            className="checkbox-margin-right"
          />
          I am registering for others
        </p>
        
        {registeringOthers && isPrivileged(state.user) && (
          <>
            <SelectField
              value={userToRegister}
              label='Contestant'
              values={users}
              setValue={(user) => {
                setError('')
                setUserToRegister(user)
              }}
            />
            <Divider />
            <br/>
          </>
        )}
        
        {shouldShowForm() && (
          <>
            <TextField
              value={firstName}
              id='firstname'
              label='First Name'
              autoComplete='firstName'
              onChange={(e) => setFirstName(e.target.value)}
            />
            
            <TextField
              value={lastName}
              id='lastname'
              label='Last Name'
              autoComplete='lastName'
              onChange={(e) => setLastName(e.target.value)}
            />
            
            <SelectField
              label='Division'
              value={division}
              values={state.divisions}
              setValue={setDivision}
            />
        
            <TextField
              value={String(age)}
              shrinkLabel
              id='age'
              type='number'
              label='Age'
              autoComplete='age'
              onChange={(e) => setAge(parseInt(e.target.value))}
            />
            
            <SelectField
              value={gender}
              label='Gender participation'
              required
              values={genders}
              setValue={setGender}
            />
            
            <Divider />
            <br/>
            
            <TextField
              value={hometown}
              id='hometown'
              label='Hometown'
              autoComplete='hometown'
              onChange={(e) => setHometown(e.target.value)}
            />
            
            <SelectField
              value={lake}
              label='Lake'
              showOther
              values={state.lakes}
              setValue={setLake}
            />
          </>
      )}
        
        
        <Message
          severity={'error'}
          message={errorMsg}
        />
        
        {registeredContestants.length > 0 && (
          <>
            <h3>Registered Contestants</h3>
            {registeredContestants.map((contestant) => (
              <Message
                key={contestant.id}
                severity='success'
                message={`Registered ${contestantDisplayName(contestant)} for ${contestant.division_data?.name} division`}
              />
            ))}
          </>
        )}
        
        <Loader loading={loading} />
        
        <Button
          id='contest-entry-submit'
          variant='outlined'
          color='primary'
          onClick={handleSubmit}
        >
          Register
        </Button>
      </DialogBox>
    </div>
  )
}

export default ContestEntryForm
