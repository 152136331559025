import { useContext } from 'react';
import {AxiosResponse} from 'axios';
import { AuthContext, AuthState, UserPayload } from './auth-context';
import {getData, postData, putData, patchData, deleteData} from '../api';
import {
  Contestant,
  Community,
  Lake,
  User,
  Division,
  Sport,
  Rule,
  Lesson,
  Level,
  Post,
  Challenge,
  ReportOption, Contest, ContestantEntry,
} from '../types';
import {getError} from '../../utils';

export interface UseAuth {
  (): {
    getUser: () => Promise<UserPayload>;
    
    getContests: () => Promise<Contest[]>;
    
    getUpcomingContest: () => Promise<Contest>;

    login: (username: string, password: string) => Promise<void>;

    refresh: () => Promise<void>;

    signup: (
      username: string,
      password: string,
      password2: string,
      email: string,
    ) => Promise<void>;

    changePassword: (
      newPassword: string,
      confirmPassword: string
    ) => Promise<void>;

    forgotPassword: (email: string) => Promise<void>;
    
    getContestants: (
      community?: Community,
      lake?: Lake,
      division?: Division
    ) => Promise<Contestant[]>;

    createContestant: (
      user: User | undefined,
      community: Community | undefined,
      lake: Lake | undefined,
      address: string,
      birthday: string,
      gender: string,
      isPrivate: boolean,
    ) => Promise<void>;
    
    editContestantEntryDivision: (
      contestantEntry: ContestantEntry | undefined,
      division: Division | undefined
    ) => Promise<AxiosResponse>;
    
    editContestantEntryPlace: (
      contestantEntry: ContestantEntry | undefined,
      place: number | undefined
    ) => Promise<AxiosResponse>;
    
    createContestEntry: (
      isSelfRegister: boolean,
      contest: Contest | undefined,
      user: User | undefined,
      firstName: string,
      lastName: string,
      lake: Lake | undefined,
      hometown: string,
      division: Division | undefined,
      gender: string,
    ) => Promise<any>;
    
    uploadVideo: (
      lesson: Lesson | undefined,
      user: User | undefined,
      contestantEntry: ContestantEntry | undefined,
      sport: Sport | undefined,
      file: File | undefined,
      isPrivate: boolean,
      challenges: number[],
      challengesDone: number[]
    ) => Promise<any>;
    
    updateContestantEntry: (
      name: string,
      place: number,
      post: Post,
    ) => Promise<void>;
    
    createLake: (
      name: string,
      city?: string,
      stateString?: string,
      zipcode?: string,
      community?: Community,
      lat?: string,
      lng?: string
    ) => Promise<void>;
  
    uploadLesson: (
      sport: Sport | undefined,
      coach: User | undefined,
      customer: Contestant | null | undefined,
    ) => Promise<void>;
    
    deletePost: (
      post: Post,
    ) => Promise<void>;

    submitReport: (
      post: Post,
      option: ReportOption | null,
      text: string
    ) => Promise<void>;

    submitAchievements: (post: Post, challenges: number[], challengesDone: number[]) => Promise<void>;

    supportEmail: (subject: string, body: string) => Promise<void>;
    
    uploadProfile: (file: File | undefined) => Promise<void>;
    
    uploadComment: (post: Post | undefined, owner: User | undefined, comment: string) => Promise<void>;

    changeUsername: (username: string) => Promise<void>;

    updateContestant: (contestant: Contestant, user: User) => Promise<void>;

    getRules: () => Promise<Rule[]>;

    getLessons: (coach: User | undefined, customer: Contestant | null | undefined) => Promise<Lesson[]>;

    getLevels: () => Promise<Level[]>;

    getCommunities: () => Promise<Community[]>;

    getLakes: (community: Community | null, ordering?: string) => Promise<Lake[]>;
    
    getUsers: (community?: Community, lake?: Lake, division?: Division) => Promise<User[]>;
    
    getUserById: (id: number) => Promise<User>;

    getDivisions: () => Promise<Division[]>;

    getSports: () => Promise<Sport[]>;

    getCoaches: (community: Community | null) => Promise<Contestant[]>;

    getChallenges: (sport: Sport | null) => Promise<Challenge[]>;

    getReportOptions: () => Promise<ReportOption[]>;

    setCommunity: (community: Community) => Promise<void>;

    setLake: (lake: Lake) => Promise<void>;

    setDivision: (division: Division) => Promise<void>;

    setSport: (sport: Sport) => Promise<void>;

    logout: () => Promise<void>;

    state: AuthState;
  };
}

export const useAuth: UseAuth = () => {
  const { state, dispatch } = useContext(AuthContext);

  const getUser = async (): Promise<UserPayload> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return getData('users/?check=true')
      .then(({ results }) => {
        if (results?.length) {
          console.log('USER CHECK:', results)
          dispatch({
            type: 'SET_AUTHENTICATION',
            user: results[0],
            logname: results[0].username,
          });
          return results[0];
        }
        return null;
      })
      .catch(({ error }) => {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        });
        return error;
      });
  };

  const login = async (username: string, password: string): Promise<void> => {
    const formData = new FormData();
    formData.append('username', username.toLowerCase());
    formData.append('password', password);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/login/', formData)
      .then((res) => {
        if (res?.status === 200 && res?.data?.access) {
          localStorage.setItem('logname', username);
          localStorage.setItem('token', res.data.access);

          dispatch({
            type: 'SET_AUTHENTICATION',
            logname: username.toLowerCase(),
            user: undefined,
          });
        } else {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        });
      });
  };

  const refresh = async (): Promise<void> => {
    dispatch({
      type: 'RESET_AUTHENTICATION',
    });
  };

  const signup = async (
    username: string,
    password: string,
    password2: string,
    email: string,
  ): Promise<any> => {
    const formData = new FormData();
    formData.append('username', username.toLowerCase());
    formData.append('password1', password);
    formData.append('password2', password2);
    formData.append('email', email);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/register/', formData)
      .then((res) => {
        if (res?.status === 201 && res?.data?.access) {
          localStorage.setItem('logname', username);

          dispatch({
            type: 'SET_AUTHENTICATION',
            logname: username.toLowerCase(),
            user: undefined,
          });
        }
        else if (res?.status !== 201)
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
        });
        return res;
      })
      .catch((error) =>
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        })
      );
  };

  const changeUsername = async (username: string): Promise<void> => {
    const formData = new FormData();
    formData.append('username', username.toLowerCase());

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return patchData('auth/user/', formData)
      .then((res: any) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
          return res
        }
        localStorage.setItem('logname', username.toLowerCase());
        dispatch({
          type: 'SET_AUTHENTICATION',
          logname: username.toLowerCase(),
          user: state.user,
        });
        return res;
      })
      .catch((error) => {
        console.log('CHANGE USERNAME ERROR:', error);
      });
  };

  const changePassword = async (
    newPassword: string,
    confirmPassword: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('new_password1', newPassword);
    formData.append('new_password2', confirmPassword);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/password/change/', formData)
      .then((res) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('CHANGE PASSWORD ERROR:', error);
      });
  };
  
  const getContests = async (): Promise<Contest[]> => {
    
    return getData('contests/?upcoming=true')
    .then(({ results }) =>  results)
    .catch(({ error }) => {
      return error;
    });
  };
  const getUpcomingContest = async (): Promise<Contest> => {
    
    return getData('contests/?upcoming=true')
    .then(({ results }) => {
      if (results?.length) {
        console.log('UPCOMING CONTESTS:', results)
        dispatch({
          type: 'SET_CONTEST',
          contest: results[0],
        });
        return results[0];
      }
      return null;
    })
    .catch(({ error }) => {
      return error;
    });
  };
  
  const forgotPassword = async (email: string): Promise<void> => {
    const formData = new FormData();
    formData.append('email', email);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/password/reset/', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('FORGOT PASSWORD ERROR:', error);
      });
  };
  
  const getContestants = async (
    community: Community | undefined,
    lake: Lake | undefined,
    division: Division | undefined
  ): Promise<Contestant[]> => {
    let url = 'contestants/'
    
    if (community) url += `?community=${community.id}&`
    else if (lake) url += `?lake=${lake.id}&`
    else if (division) url += `?division=${division.id}&`
    
    return getData(url)
    .then(({ results }) =>  results)
    .catch(({ error }) => {
      return error;
    });
  }
  
  const createContestant = async (
    user: User | undefined,
    community: Community | undefined,
    lake: Lake | undefined,
    address: string,
    birthday: string,
    gender: string,
    isPrivate: boolean,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('owner', String(user?.id));
    // formData.append('community', String(community?.id));
    if (lake?.id) formData.append('lake', String(lake?.id));
    formData.append('address', address);
    formData.append('birthday', birthday);
    formData.append('gender', gender.toLowerCase());
    formData.append('is_private', String(isPrivate));
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });
    
    return postData('contestants/', formData)
    .then((res) => {
      if (res?.status === 201) {
        dispatch({
          type: 'SET_CONTESTANT',
          contestant: res.data,
        });
      } else {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error: res?.data,
        });
      }
      return res;
    })
    .catch((error) =>
      dispatch({
        type: 'ERROR_AUTHENTICATION',
        error,
      })
    );
  };
  
  const createContestEntry = async (
    isSelfRegister: boolean,
    contest: Contest | undefined,
    user: User | undefined,
    firstName: string,
    lastName: string,
    lake: Lake | undefined,
    hometown: string,
    division: Division | undefined,
    gender: string,
  ): Promise<any> => {
    const formData = new FormData();
    formData.append('is_self_register', String(isSelfRegister));
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('hometown', hometown);
    formData.append('gender', gender.toLowerCase());
    if (lake?.id) formData.append('lake', String(lake.id));
    if (division?.id) formData.append('division', String(division.id));
    if (user?.id) formData.append('user_id', String(user.id));

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData(`contests/${contest?.id}/add_contestant`, formData)
      .then((res) => {
        if (res?.status === 201) {
          dispatch({
            type: 'SET_CONTESTANT',
            contestant: res.data,
          });
        } else {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) =>
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        })
      );
  };
  
  const editContestantEntryDivision = async (
    contestantEntry: ContestantEntry | undefined,
    division: Division | undefined,
  ) => {
    const formData = new FormData();
    formData.append('division', String(division?.id));
    
    return patchData(`contestant-entries/${contestantEntry?.id}`, formData)
  }
  
  const editContestantEntryPlace = async (
    contestantEntry: ContestantEntry | undefined,
    place: number | undefined,
  ) => {
    const formData = new FormData();
    formData.append('place', String(place));
    
    console.log(contestantEntry)
    return patchData(`contestant-entries/${contestantEntry?.id}`, formData)
  }

  const uploadVideo = async (
    lesson: Lesson | undefined,
    user: User | undefined,
    contestantEntry: ContestantEntry | undefined,
    sport: Sport | undefined,
    file: File | undefined,
    isPrivate: boolean,
    challenges: number[],
    challengesDone: number[]
): Promise<any> => {
    const formData = new FormData();
    
    if (user?.id) formData.append('owner', String(user?.id));
    formData.append('sport', String(sport?.id));
    formData.append('post_filename', file?.name || '');
    formData.append('is_private', String(isPrivate));
    
    if (lesson?.id) formData.append('lesson', String(lesson?.id));
    if (contestantEntry?.id) formData.append('contestant_entry', String(contestantEntry?.id));
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('posts/', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        } else {
          challenges?.forEach((challengeID) => {
            const formData2 = new FormData();
            formData2.append('post', res.data?.id);
            formData2.append('challenge', String(challengeID));
            
            let inProgress = true
            if (challengesDone.includes(challengeID)) {
              inProgress = false
              // challengesDone.splice()
            }
            formData2.append('in_progress', String(inProgress));

            postData('achievements/', formData2);
          });
          
          challengesDone?.forEach((challengeID) => {
            if (!challenges.includes(challengeID)) {
              const formData3 = new FormData();
              formData3.append('post', String(res.data?.id));
              formData3.append('challenge', String(challengeID));
              formData3.append('in_progress', 'false');
              postData('achievements/', formData3);
            }
          })
        }
        return res;
      })
      .catch((error) => {
        console.log('UPLOAD POST ERROR:', error);
      });
  };
  
  
  const uploadLesson = async (
    sport: Sport | undefined,
    coach: User | undefined,
    customer: Contestant | null | undefined,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('sport', String(sport?.id));
    formData.append('coach', String(coach?.id));
    formData.append('customer', String(customer?.id));
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });
    
    return postData('lessons/', formData)
    .then((res) => {
      if (res?.status !== 201)
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error: res?.data,
        });
      return res;
    })
    .catch((error) => {
      console.log('UPLOAD LESSON ERROR:', error);
    });
  };
  
  const deletePost = async (
    post: Post,
  ): Promise<void> => {
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });
  
    return deleteData(`/posts/${post.id}`).then((res: any) => {
      if (res?.status === 204) {
        return res
      }
      dispatch({
        type: 'ERROR_AUTHENTICATION',
        error: res?.data,
      });
      return res
    }).catch((error) => {
      console.log('DELETE POST ERROR:', error);
    });
  };
  
  
  const uploadComment = async (
    post: Post | undefined,
    owner: User | undefined,
    comment: string,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('post', String(post?.id))
    formData.append('owner', String(owner?.id))
    formData.append('text', comment)
    formData.append('is_feedback', 'true')

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('comments/', formData)
      .then((res) => {
        if (res?.status !== 201)
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        return res;
      })
      .catch((error) => {
        console.log('UPLOAD COMMENT ERROR:', error);
      });
  };


  const uploadProfile = async (file: File | undefined): Promise<void> => {
    const formData = new FormData();
    formData.append('filename', file || '');

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('profiles/', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('UPLOAD POST ERROR:', error);
      });
  };
  
  const updateContestantEntry = async (name: string, place: number, post: Post): Promise<void> => {
    const formData = new FormData();
    formData.append('place', String(place));
    
    return putData(`posts/${post.id}/`, formData)
  };
  
  const updateContestant = async (contestant: Contestant, user: User): Promise<void> => {
    const formData = new FormData();
    formData.append('owner', String(user.id));
    
    if (contestant.community_data)
      formData.append('community', String(contestant.community_data.id));
    
    if (contestant.lake_data)
      formData.append('lake', String(contestant.lake_data.id));
    
    formData.append('address', String(contestant.address));
    formData.append('birthday', String(contestant.birthday));
    formData.append('gender', String(contestant.gender).toLowerCase());
    formData.append('boat', String(contestant.boat));
    formData.append('board', String(contestant.board));
    formData.append('is_coach', String(contestant.is_coach));
    formData.append('is_judge', String(contestant.is_judge));
    formData.append('is_private', String(contestant.is_private));

    return putData(`contestants/${contestant.id}/`, formData)
  };

  const supportEmail = async (subject: string, body: string): Promise<void> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', body);

    return postData('support/', formData)
      .then((res) => {
        if (res?.status !== 200)
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        return res;
      })
      .catch((error) => {
        console.log('SUBMIT REPORT ERROR:', error);
      });
  };
  
  const createLake = async (
    name: string,
    city?: string,
    stateString?: string,
    zipcode?: string,
    community?: Community,
    lat?: string,
    lng?: string,
  ): Promise<void> => {
    
    const formData = new FormData();
    formData.append('name', name)
  
    if (community) formData.append('community', String(community.id))
    if (city) formData.append('city', city)
    if (stateString) formData.append('state', stateString)
    if (zipcode) formData.append('zipcode', zipcode)
    if (lat && lng) {
      formData.append('latitude', lat)
      formData.append('longitude', lng)
    }
    
    return postData('lakes/', formData)
    .then((res) => {
      if (res?.status !== 201)
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error: getError(res?.data),
        })
      return res
    })
    .catch((error) => {
      console.log('SUBMIT REPORT ERROR:', error)
    })
  }
  
  const submitReport = async (
    post: Post,
    option: ReportOption | null,
    text: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('post', String(post.id));
    formData.append('option', String(option?.id));
    formData.append('description', String(text));

    dispatch({
      type: 'START_AUTHENTICATION',
    })

    return postData('reports/', formData)
      .then((res) => {
        if (res?.status !== 201)
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: getError(res?.data),
          })
        return res
      })
      .catch((error) => {
        console.log('SUBMIT REPORT ERROR:', error);
      })
  };

  const submitAchievements = async (
    post: Post,
    challenges: number[],
    challengesDone: number[]
): Promise<void> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    })

    challenges?.forEach((challengeID) => {
      const formData = new FormData();
      formData.append('post', String(post.id));
      formData.append('challenge', String(challengeID));
      
      let inProgress = true
      if (challengesDone.includes(challengeID)) {
        inProgress = false
        // challengesDone.splice()
      }
      formData.append('in_progress', String(inProgress));
      
      postData('achievements/', formData);
    })
    challengesDone?.forEach((challengeID) => {
      if (!challenges.includes(challengeID)) {
        const formData = new FormData();
        formData.append('post', String(post.id));
        formData.append('challenge', String(challengeID));
        formData.append('in_progress', 'false');
        postData('achievements/', formData);
      }
    })
  }

  const getLessons = async (coach: User | undefined, customer: Contestant | null | undefined): Promise<Lesson[]> => {
    const formData = new FormData()
    formData.append('post', String(coach?.id))
    formData.append('challenge', String(customer?.id))
    dispatch({
      type: 'START_AUTHENTICATION',
    })

    return getData('lessons/')
      .then((lessons) => {
        dispatch({
          type: 'SET_LESSONS',
          lessons,
        });
        return lessons
      })
      .catch(({ res }) => {
        return res
      })
  }


  const getLevels = async (): Promise<Level[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return getData('levels/')
      .then((levels) => {
        dispatch({
          type: 'SET_LEVELS',
          levels,
        })
        return levels
      })
      .catch(({ res }) => {
        return res
      })
  }

  const getRules = async (): Promise<Rule[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    })

    return getData('rules/')
      .then(({ results }) => {
        dispatch({
          type: 'SET_RULES',
          rules: results,
        })
        return results
      })
      .catch(({ res }) => {
        return res
      })
  }

  const getCommunities = async (): Promise<Community[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    })

    return getData('community/')
      .then(({ results }) => {
        dispatch({
          type: 'SET_COMMUNITIES',
          communities: results,
        })
        return results
      })
      .catch(({ res }) => {
        return res
      })
  }
  
  const getUserById = async (id: number): Promise<User> => {
    let url = `users/${id}`
    
    return getData(url)
    .then((res) => {
      console.log('res', res)
      dispatch({
        type: 'SET_USERS',
        users: res?.results,
      })
      return res?.results
    })
    .catch(({ res }) => {
      return res
    })
  }
  
  const getUsers = async (community?: Community, lake?: Lake, division?: Division): Promise<User[]> => {
    let url = 'users/'
  
    if (community) url += `?community=${community?.id}&`
    else if (state.community) url += `?community=${state.community?.id}&`
    
    if (lake) url += `?lake=${lake?.id}&`
    else if (state.community) url += `?community=${state.community?.id}&`
    
    if (division) url += `?division=${division?.id}&`
    else if (state.community) url += `?community=${state.community?.id}&`
    
    return getData(url)
    .then((res) => {
      console.log('res', res)
      dispatch({
        type: 'SET_USERS',
        users: res?.results,
      })
      return res?.results
    })
    .catch(({ res }) => {
      return res
    })
  }
  
  const getLakes = async (community: Community | null, ordering?: string): Promise<Lake[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    })

    let url = 'lakes/'
    
    if (ordering) url += `?ordering=${ordering}&`

    if (community) url += `?community=${community?.id}&`
    else if (state.community) url += `?community=${state.community?.id}&`

    return getData(url)
      .then(({ results }) => {
        dispatch({
          type: 'SET_LAKES',
          lakes: results,
        })
        return results
      })
      .catch(({ res }) => {
        return res
      })
  }

  const getDivisions = async (): Promise<Division[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    })

    return getData('divisions/')
      .then(({ results }) => {
        dispatch({
          type: 'SET_DIVISIONS',
          divisions: results,
        })
        return results
      })
      .catch(({ res }) => {
        return res
      })
  }


  const getSports = async (): Promise<Sport[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    })

    return getData('sports/')
      .then(({ results }) => {
        dispatch({
          type: 'SET_SPORTS',
          sports: results,
        })
        return results
      })
      .catch(({ res }) => {
        return res
      })
  }


  const getCoaches = async (community: Community | null): Promise<Contestant[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return getData('users/?coach=true')
      .then((res) => {
        console.log('COACHES RESULTS:', res)
        const coaches = res?.results
        dispatch({
          type: 'SET_COACHES',
          coaches,
        });
        return coaches;
      })
      .catch(({ res }) => {
        return res;
      });
  };

  const getReportOptions = async (): Promise<ReportOption[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return getData('report-options/')
      .then((reportOptions) => {
        dispatch({
          type: 'SET_REPORT_OPTIONS',
          reportOptions,
        });
        return reportOptions;
      })
      .catch(({ res }) => {
        return res;
      });
  };

  const getChallenges = async (sport: Sport | null): Promise<Challenge[]> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    let url = 'challenges/';

    if (sport) url += `?sport=${sport.id}`;
    return getData(url)
      .then((challenges) => {
        return challenges;
      })
      .catch(({ res }) => {
        return res;
      });
  };

  const setCommunity = async (community: Community): Promise<void> => {
    dispatch({
      type: 'SET_COMMUNITY',
      community,
    });
  };

  const setLake = async (lake: Lake): Promise<void> => {
    dispatch({
      type: 'SET_LAKE',
      lake,
    });
  };

  const setDivision = async (division: Division): Promise<void> => {
    dispatch({
      type: 'SET_DIVISION',
      division,
    });
  };

  const setSport = async (sport: Sport): Promise<void> => {
    dispatch({
      type: 'SET_SPORT',
      sport,
    });
  };

  const logout = async (): Promise<void> => {
    const formData = new FormData();

    dispatch({
      type: 'CLEAR_AUTHENTICATION',
    });

    return postData('auth/logout/', formData).then(() =>
      window.location.reload()
    );
  };

  return {
    getUser,
    getUserById,
    login,
    refresh,
    signup,
    changeUsername,
    createContestant,
    uploadVideo,
    submitReport,
    submitAchievements,
    supportEmail,
    uploadLesson,
    uploadProfile,
    deletePost,
    uploadComment,
    createContestEntry,
    updateContestantEntry,
    editContestantEntryDivision,
    editContestantEntryPlace,
    updateContestant,
    changePassword,
    forgotPassword,
    getRules,
    getLevels,
    getLessons,
    getCommunities,
    getLakes,
    createLake,
    getUsers,
    getDivisions,
    getSports,
    getUpcomingContest,
    getContestants,
    getContests,
    getCoaches,
    getChallenges,
    getReportOptions,
    setCommunity,
    setLake,
    setDivision,
    setSport,
    logout,
    state,
  };
};
