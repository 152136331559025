import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getData } from '../../state-management/api';
import {
  User,
  Community,
  Lake,
  Sport,
  Post,
  Division,
  Lesson,
} from '../../state-management/types';
import { PostComponent, Loader } from '..';

import './styles.scss';

interface PostListProps {
  user?: User | null;
  community?: Community | null;
  lake?: Lake | null;
  sport?: Sport | null;
  division?: Division | null;
  lesson?: Lesson | null;
}

const PostList: React.FC<PostListProps> = ({
  user,
  community,
  lake,
  sport,
  division,
  lesson,
}) => {
  const [posts, setPosts] = React.useState<Post[]>([]);
  const [nextURL, setNextURL] = React.useState('');
  const [loaderFrag, setLoaderFrag] = React.useState<boolean>(false);
  const getMoreData = () => {
    console.log('nexturl:', nextURL);
    if (nextURL)
      getData(nextURL).then((data) => {
        const tmpPosts = posts;

        if (data?.results?.length) {
          data.results.forEach((post: never) => {
            tmpPosts.push(post);
          });

          setPosts(tmpPosts);
          setNextURL(data.next);
        }
      }).catch((err) => {
        console.log(err)
      });
  };

  React.useEffect(() => {
    let url = 'posts/?';

    if (user) url += `owner=${user?.id}&`;

    if (community?.id) url += `community=${community?.id}&`;

    if (lake?.id) url += `lake=${lake?.id}&`;

    if (division?.id) url += `division=${division?.id}&`;

    if (sport?.id) url += `sport=${sport?.id}&`;

    if (lesson?.id) url += `lesson=${lesson?.id}&`;
    
    setLoaderFrag(true)
    getData(url)
      .then(({ results, next }) => {
        // console.log('NEXT:', next);
        setPosts(results);
        setNextURL(next);
        setLoaderFrag(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user, community, lake, division, sport, lesson?.id]);

  const loaderFragment = (
    <>
      <h3>Loading posts...</h3>
      <Loader loading={true} />
    </>
  )
  
  const postsComponent = (
    <InfiniteScroll
      dataLength={posts?.length}
      next={getMoreData}
      loader={<Loader loading={true} />}
      hasMore={Boolean(nextURL)}
    >
      {posts?.map((post: Post) => (
        <PostComponent key={post.id} post={post} />
      ))}
    </InfiniteScroll>
  )
  
  if (loaderFrag)
    return loaderFragment
  if (posts?.length)
    return postsComponent
  
  return (
    <h2>
      No posts to show for this filter
    </h2>
  );
};

export default PostList;
