import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteData, useAuth } from '../../state-management';
import {Lesson, Post, User} from '../../state-management/types';
import { ConfirmModal } from '..';
import {VideoForm, CommentForm} from '../../forms';
import {isPrivileged} from '../../utils';
import './styles.scss';

interface LessonMenuProps {
  lesson?: Lesson;
  currentPost?: Post;
  user?: User;
}

const LessonMenu: React.FC<LessonMenuProps> = ({ lesson, currentPost, user }) => {
  const { state, getUser } = useAuth();

  const [msg, setMsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setLoading(true);
    // TODO: let unprivileged users hide lesson only instead of delete
    deleteData(`/lessons/${lesson?.id}`).then((res: any) => {
      if (res?.status === 204) {
        setMsg('Lesson deleted');
        setLoading(false);
        getUser();
      }
    });
  };
  
  return (
    <div className='lg-post-menu'>
      <IconButton aria-label='extra' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
  
      {user?.id === state?.user?.id || isPrivileged(state?.user) ? (
        <Menu
          id='post-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{
            paper: 'lg-post-menu--paper',
            list: 'lg-post-menu--list',
          }}
        >
          {isPrivileged(state?.user) ? (
            <MenuItem button className='lg-post-menu--item'>
              <VideoForm lesson={lesson} allowComment={true} removeButton={true} sportIn={lesson?.sport_data} user={user}/>
            </MenuItem>
          ) : null}
  
          <MenuItem button className='lg-post-menu--item'>
            <CommentForm isCoach={isPrivileged(state?.user)} post={currentPost}/>
          </MenuItem>
        
          <MenuItem button className='lg-post-menu--item'>
            <ConfirmModal
              action='delete this lesson'
              msg={msg}
              onSubmit={handleDelete}
              errorMsg={state.error}
              loading={loading}
            />
          </MenuItem>
        </Menu>
      ) : null}
    </div>
  );
};

export default LessonMenu;
