import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {FilterDialog, Grid, LakeComponent} from '../../components';
import {useAuth} from '../../state-management';
import {LakeForm} from '../../forms';
import './styles.scss';


const Lakes: React.FC<RouteComponentProps> = () => {
  const { state, getLakes } = useAuth();
  
  React.useEffect(() => {
    getLakes(state.community).catch((error) => {
      console.log(error)
    })
  }, [state.community]) // eslint-disable-line
  
  const firstLake = state.lakes?.length > 0 ? state.lakes[0] : undefined
  
  const lakesComponent = (
    <div className='lake-games--lakes'>
      <div className='lake-games--lakes-header'>Lakes</div>
      <FilterDialog lake />
      <LakeForm/>
      <LakeComponent lake={state.lake || firstLake}/>
    </div>
  );

  return (
    // @ts-ignore
    <Grid
      items={[
        {
          id: 'lake-games--lakes',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: lakesComponent,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Lakes);
