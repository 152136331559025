import * as React from 'react';
import { getData } from '../../state-management/api';
import {
  Achievement,
  User,
  Community,
  Lake,
  Sport,
  Division,
} from '../../state-management/types';
import {Loader, TrickBadge} from '..';
import './styles.scss';

interface AchievementsListProps {
  user?: User;
  community?: Community;
  lake?: Lake;
  sport?: Sport;
  division?: Division;
}

const AchievementsList: React.FC<AchievementsListProps> = ({
  user,
  community,
  lake,
  sport,
  division,
}) => {
  
  const [achievements, setAchievements] = React.useState<Achievement[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true)
    
    let url = 'achievements/?';
    
    if (user?.contestant_data) url += `owner=${user.contestant_data.id}&`;
    if (community) url += `community=${community.id}&`;
    if (lake) url += `lake=${lake.id}&`;
    if (division) url += `division=${division.id}&`;
    if (sport) url += `sport=${sport.id}&`;

    getData(url).then((data) => {
      setAchievements(data);
      setLoading(false);
    });
  }, [user, community, lake, division, sport]);

  return (
    <>
      <Loader loading={loading} />
      {achievements.length ? (
        <>
          {achievements?.map((achievement: Achievement) => (
            <TrickBadge
              key={achievement.id}
              owner={user}
              challenge={achievement.challenge_data}
              colored
            />
          ))}
        </>
      ) : (
        <>
          {loading ? (
            <Loader loading={loading}/>
          ) : (
            <div>No {`${sport?.name} `}achievements yet!</div>
          )}
        </>
      )}
    </>
  );
};

export default AchievementsList;
