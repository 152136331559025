import {Contestant, User} from '../state-management/types';

export const check = (query?: string | number | null): boolean =>
  query !== null &&
  query !== undefined &&
  query !== -1 &&
  query !== '-1' &&
  query !== 'All' &&
  query !== 'undefined' &&
  query !== 'null' &&
  query !== '';

export const LIFECYCLE = check(window.LIFECYCLE)
  ? window.LIFECYCLE
  : 'local';

export const getCurrentUrl = (hostname: string): string => {
  if (hostname === 'localhost') return 'http://localhost:8000/'
  
  return encodeURI(`https://${window.location.hostname}/`);
};

export const CURRENT_URL = getCurrentUrl(window.location.hostname)

export const APP_CODE = check(window.APP_CODE)
  ? window.APP_CODE
  : 'lakegames';

export const BACKEND_BUCKET = check(window.BACKEND_BUCKET)
  ? window.BACKEND_BUCKET
  : `dev-${APP_CODE}-v3-backend-bucket`

export const PUBLIC_BUCKET = check(window.PUBLIC_BUCKET)
  ? window.PUBLIC_BUCKET
  : `dev-${APP_CODE}-v3-public-content`

export const MAPS_KEY = check(window.MAPS_KEY)
  ? window.MAPS_KEY
  : 'AIzaSyCsEH05FVupVn8hdMEZmi_ZL7TfYu7U_FU';


export const contestantDisplayName = (c: any) => {
  if (!c.first_name || !c.last_name) return c.username
  return `${c.first_name} ${c.last_name}`
}


export const isPrivileged = (user: User | undefined) => {
  if (user?.is_superuser || user?.is_staff || user?.contestant_data?.is_coach)
    return true
  return false
}

const getTimeDiffStr = (num: number, str: string) => {
    let returnStr = str
    if (num !== 1)
      returnStr += 's'
    return `${num} ${returnStr} ago`
}

export const getTimeDiff = (created: string) => {
  
  const dateCreated = new Date(created)
  const today = new Date()
  const diff = today.getTime() - dateCreated.getTime();
  
  let msec = diff;
  const yy = Math.floor(msec / 1000 / 60 / 60 / 24 / 30 / 365.25);
  msec -= yy * 1000 * 60 * 60 * 24 * 30 * 365.25;
  const mo = Math.floor(msec / 1000 / 60 / 60 / 24 / 30);
  msec -= mo * 1000 * 60 * 60 * 24 * 30;
  const dd = Math.floor(msec / 1000 / 60 / 60 / 24);
  msec -= dd * 1000 * 60 * 60 * 24;
  const hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  const mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  const ss = Math.floor(msec / 1000);
  
  if (yy > 0) {
    return getTimeDiffStr(yy, 'year')
  }
  if (mo > 0) {
    return getTimeDiffStr(mo, 'month')
  }
  if (dd > 0) {
    return getTimeDiffStr(dd, 'day')
  }
  if (hh > 1) {
    return getTimeDiffStr(hh, 'hour')
  }
  if (mm > 1) {
    return getTimeDiffStr(mm, 'minute')
  }
  return getTimeDiffStr(ss, 'second')
  
}


export const getError = (data: any): string => {

  if (data?.non_field_errors?.length) {
    return JSON.stringify(data.non_field_errors[0]);
  }
  if (data?.error) return data.error;
  if (data?.detail) return data.detail;
  if (data?.username?.length > 0) return data.username[0];
  if (data?.new_password2) return data.new_password2;
  if (data?.sport) return data.sport;
  if (data?.community) return data.community;

  if (data) return JSON.stringify(data);
  return data
};

export const fixURI = (url: string): string => {
  let ret = url;
  if (!url.includes('localhost')) ret = url.replace('http', 'https');
  return encodeURI(ret);
};

export const getBucketUrl = (name: string): string => {
  return `https://storage.googleapis.com/${name}`;
};

export const publicBucketUrl = getBucketUrl(PUBLIC_BUCKET)
export const backendBucketUrl = getBucketUrl(BACKEND_BUCKET)
