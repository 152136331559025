import * as React from 'react';
import {getData, useAuth} from '../../state-management';
import {Contestant, Division, Post, User} from '../../state-management/types';
import {Avatar, PostComponent, ScoreboardCard, ScoreboardPostList} from '..';
import './styles.scss';


const PostsScoreboard: React.FC = () => {
  const { state } = useAuth();
  
  return (
    <>
      <ScoreboardPostList
        community={state.community}
        sport={state.sport}
      />
      {state.divisions?.map((division: Division) => (
        <div key={division.id}>
          <ScoreboardPostList
            division={division}
            community={state.community}
            sport={state.sport}
          />
        </div>
      ))}
    </>
  );
};

export default PostsScoreboard;
