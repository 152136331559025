import * as React from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loader, Message, Grid, TextField, IconButton } from '../../components';
import { useAuth } from '../../state-management';
import { api } from '../../state-management/axios';
import './styles.scss';

interface SupportProps {
  previous: {
    location: string;
  };
}

const Support: React.FC<SupportProps & RouteComponentProps> = () => {
  const { state, supportEmail } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [body, setBody] = React.useState('');
  const [msg, setMsg] = React.useState('');

  const handleSubmit = () => {
    setMsg('');
    setLoading(true);
    supportEmail('SUPPORT REQUEST', body).then((res: any) => {
      if (res?.status === 200) {
        setMsg(res.data);
      }
      setLoading(false);
    });
  };

  const supportForm = (
    <div className='lake-games--support'>
      <div className='lake-games--support-header'>Lake Games Support</div>

      <TextField
        value={body}
        id='message'
        label='Message'
        onChange={(e) => setBody(e.target.value)}
        handleSubmit={handleSubmit}
      />
      <div className='lake-games--support-actions'>
        <Loader loading={loading} />
        <IconButton
          tooltipTitle='Lake Games Support'
          icon={HiOutlineChevronRight}
          onClick={handleSubmit}
          disabled={!body.length}
        />
      </div>
      <Message
        severity={state.error ? 'error' : 'success'}
        message={state.error || msg}
      />

    </div>
  );

  return (
    // @ts-ignore
    <Grid
      items={[
        {
          id: 'lake-games--support',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: supportForm,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Support);
