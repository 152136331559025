import * as React from 'react';
import { useAuth } from '../../state-management';
import { DialogBox, DropdownMenu } from '..';
import './styles.scss';

interface FilterProps {
  community?: boolean;
  lake?: boolean;
  division?: boolean;
  sport?: boolean;
}
const FilterDialog: React.FC<FilterProps> = ({
  community,
  lake,
  division,
  sport,
}) => {
  const {
    state,
    getCommunities,
    getLakes,
    getDivisions,
    getSports,
    setCommunity,
    setLake,
    setDivision,
    setSport,
  } = useAuth();

  React.useEffect(() => {
    getCommunities();
  }, []); // eslint-disable-line

  React.useEffect(() => {
    getLakes(state.community);
  }, [state.community]); // eslint-disable-line

  React.useEffect(() => {
    getDivisions();
    getSports();
  }, []); // eslint-disable-line

  return (
    <DialogBox title='Filter'>
      {community ? (
        <DropdownMenu
          title='Communities'
          value={state.community || ''}
          values={state.communities || []}
          setValue={setCommunity}
        />
      ) : null}

      {lake ? (
        <DropdownMenu
          title='Lakes'
          value={state.lake || ''}
          values={state.lakes || []}
          setValue={setLake}
        />
      ) : null}

      {division ? (
        <DropdownMenu
          title='Divisions'
          value={state.division || ''}
          values={state.divisions || []}
          setValue={setDivision}
        />
      ) : null}

      {sport ? (
        <DropdownMenu
          title='Sports'
          value={state.sport || ''}
          values={state.sports || []}
          setValue={setSport}
        />
      ) : null}
    </DialogBox>
  );
};

export default FilterDialog;
