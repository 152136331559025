import * as React from 'react';
import { getData } from '../../state-management/api';
import {
  Community,
  Lake,
  Sport,
  Division,
  Challenge,
} from '../../state-management/types';
import {Loader, TrickBadge} from '..';

import './styles.scss';

interface ChallengesListProps {
  community?: Community;
  lake?: Lake;
  sport?: Sport;
  division?: Division;
}

const ChallengesList: React.FC<ChallengesListProps> = ({
  community,
  lake,
  sport,
  division,
}) => {
  
  const [challenges, setChallenges] = React.useState<Challenge[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true)
    
    let url = 'challenges/?';

    if (community) url += `community=${community.id}&`;
    if (lake) url += `lake=${lake.id}&`;
    if (division) url += `division=${division.id}&`;
    if (sport) url += `sport=${sport.id}&`;

    getData(url).then((data) => {
      setChallenges(data);
      setLoading(false);
    });
  }, [community, lake, division, sport]);

  return (
    <>
      <Loader loading={loading} />
      {challenges.length ? (
        <>
          {challenges?.map((challenge: Challenge) => (
            <TrickBadge key={challenge.id} challenge={challenge} />
          ))}
        </>
      ) : (
        <>
          {loading ? (
            <Loader loading={loading}/>
          ) : (
            <div>No {`${sport?.name} `}challenges yet!</div>
          )}
        </>
      )}
    </>
  );
};

export default ChallengesList;
