import * as React from 'react';
import {Link} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import {Division, Sport} from '../../state-management/types';
import './styles.scss';

interface ScoreboardRow {
  id: number
  name: string
  score: number
  avatar?: string
  link?: string
}

interface ScoreboardCardProps {
  rows?: ScoreboardRow[];
  getRows?: any;
  division?: Division | null;
  sport?: Sport | null;
}

const ScoreboardCard: React.FC<ScoreboardCardProps> = ({ division, sport, getRows, rows }) => {
  const [cardRows, setCardRows] = React.useState<ScoreboardRow[]>(rows ?? [])
  
  React.useEffect(() => {
    if (!rows && getRows) {
      getRows().then((data: ScoreboardRow[]) => {
        setCardRows(data)
      })
    }
  }, [sport]) // eslint-disable-line
  
  const finalRows = rows ?? cardRows
  
  return (
    <>
      {finalRows?.length ? (
        <div>
          <h3 className="scores-header"> {division?.name || 'All Divisions'} - {sport?.name || 'All Sports'} </h3>
  
          <table className="table table-bordered scores-table">
            <tbody className="scores-row">
            {finalRows.map((row: ScoreboardRow, index: number) => (
              <tr key={row.id} className="scores-row">
                <td className="scores-count">
                  {index + 1}
                </td>
  
                <Link  to={row.link ?? ''} className="scoreboard-item-link">
                  <td className="scores-avatar">
                    <Avatar alt={row.name.charAt(0)} src={row.avatar} />
                  </td>
                
                  <td className="scores-name">
                    {row.name}
                  </td>
                </Link>
  
                <td className="scores-points">
                  {row.score}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
};

export default ScoreboardCard
