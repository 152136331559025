import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MdSettings } from 'react-icons/md';
import { UserPrivileges } from '../../forms';
import {User} from '../../state-management/types';
import './styles.scss';


interface AdminSettingsMenuProps {
  user: User;
}

const AdminSettingsMenu: React.FC<AdminSettingsMenuProps> = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='lg-settings-menu'>
      <IconButton aria-label='extra' onClick={handleClick}>
        <MdSettings />
      </IconButton>

      <Menu
        id='post-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: 'lg-settings-menu--paper',
          list: 'lg-settings-menu--list',
        }}
      >
        <MenuItem button className='lg-settings-menu--item'>
          <UserPrivileges user={user} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AdminSettingsMenu;
