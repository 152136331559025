import axios from 'axios';
import { CURRENT_URL } from '../utils';

function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const api = () => {
  axios.defaults.withCredentials = true;
  
  const token = localStorage.getItem('token');
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  
  const csrfToken = getCookie('csrftoken');
  if (csrfToken) axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
  
  return axios.create({ baseURL: `${CURRENT_URL}api/`, timeout: 600000 });
};

export default api();
