import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { useAuth } from '../../state-management';
import { Post, Challenge } from '../../state-management/types';
import { Message, Loader, ChallengesScrollField } from '../../components';
import './styles.scss';

interface AchievementsFormProps {
  post?: Post;
}
const AchievementsForm: React.FC<AchievementsFormProps> = ({ post }) => {
  const { state, getChallenges, submitAchievements } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [msg, setMsg] = React.useState('');

  const [challenges, setChallenges] = React.useState<Challenge[]>([]);
  const [selectedChallenges, setSelected] = React.useState<
    number[] | undefined
  >([]);
  const [selectedDoneChallenges, setSelectedDone] = React.useState<
    number[] | undefined
    >([]);

  React.useEffect(() => {
    getChallenges(post?.sport_data || null).then((challengesList) => {
      setChallenges(challengesList);
    });
    setSelected([]);
  }, [post]); // eslint-disable-line

  const handleClick = () => {
    setMsg('');
    setOpen(!open);
  };

  const handleSubmit = () => {
    if (post) {
      setLoading(true);
      submitAchievements(post, selectedChallenges || [], selectedDoneChallenges || []).then((res: any) => {
        setMsg('Successfully added tricks');
        setLoading(false);
      });
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        aria-controls='submit-achievements-menu'
        aria-haspopup='true'
      >
        Add Trick
      </div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClick}
        aria-labelledby='submit-achievements-dialog'
      >
        <DialogTitle id='submit-achievements'>Add Trick</DialogTitle>

        <DialogContent>
          <ChallengesScrollField
            challenges={challenges}
            selectedChallenges={selectedChallenges}
            selectedDoneChallenges={selectedDoneChallenges}
            setSelected={setSelected}
            setSelectedDone={setSelectedDone}
          />
        </DialogContent>

        <DialogActions>
          <Loader loading={loading} />
          <Message
            severity={state.error ? 'error' : 'success'}
            message={state.error || msg}
          />
          <div className='edit-achievements-submit-btn'>
            <Button onClick={handleSubmit} color='primary'>
              Submit
            </Button>
          </div>
          <div className='edit-achievements-cancel-btn'>
            <Button onClick={handleClick} color='primary'>
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AchievementsForm;
