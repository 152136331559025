import * as React from 'react';
import {Paper} from '@material-ui/core';
import {Challenge, User} from '../../state-management/types';
import {publicBucketUrl} from '../../utils';
import './styles.scss';

interface TrickBadgeProps {
    challenge: Challenge;
    owner?: User;
    colored?: boolean;
}

const TrickBadge: React.FC<TrickBadgeProps> = ({challenge, owner}) => {
    if (!challenge) return null
    
    console.log('CHALLENGE', challenge)
    const sport = challenge.sport_data?.name.toLowerCase();
    const color = challenge.level_data?.color;
    const gender = owner?.contestant_data?.gender || 'male';
    const image = `${color}-${sport}-${gender}`;

    const url = `${publicBucketUrl}/icons/${sport}/${color}/${image}.png`;

    return (
        <Paper elevation={3} className='lg-trick-badge'>
            <div className='lg-trick-badge--name'>
                <img height={50} width={50} src={url} alt='N/A'/>
                <br/>
                {challenge.name}
            </div>
            <div className='lg-trick-badge--points'>{challenge.points}</div>
        </Paper>
    );
};

export default TrickBadge;
