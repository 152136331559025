import { Dispatch } from 'react';
import LogRocket from 'logrocket';
import { AuthState, defaultState } from './auth-context';
import { getError } from '../../utils';
import {
  User,
  Community,
  Lake,
  Division,
  Sport,
  Rule,
  Lesson,
  Level,
  Contestant,
  ReportOption,
  Challenge, Contest,
} from '../types';

export type AuthAction =
  | { type: 'SET_AUTHENTICATION'; logname: string; user: User | undefined }
  | { type: 'SET_CONTEST'; contest: Contest }
  | { type: 'START_AUTHENTICATION' }
  | { type: 'SET_CONTESTANT'; contestant: Contestant | null }
  | { type: 'ERROR_AUTHENTICATION'; error: string }
  | { type: 'CLEAR_AUTHENTICATION' }
  | { type: 'RESET_AUTHENTICATION' }
  | { type: 'SET_USERS'; users: User[] }
  | { type: 'SET_RULES'; rules: Rule[] }
  | { type: 'SET_LESSONS'; lessons: Lesson[] }
  | { type: 'SET_LEVELS'; levels: Level[] }
  | { type: 'SET_COMMUNITIES'; communities: Community[] }
  | { type: 'SET_LAKES'; lakes: Lake[] }
  | { type: 'SET_DIVISIONS'; divisions: Division[] }
  | { type: 'SET_SPORTS'; sports: Sport[] }
  | { type: 'SET_COACHES'; coaches: User[] }
  | { type: 'SET_CHALLENGES'; challenges: Challenge[] }
  | { type: 'SET_REPORT_OPTIONS'; reportOptions: ReportOption[] }
  | { type: 'SET_COMMUNITY'; community: Community }
  | { type: 'SET_LAKE'; lake: Lake }
  | { type: 'SET_DIVISION'; division: Division }
  | { type: 'SET_SPORT'; sport: Sport };

export type AuthDispatch = Dispatch<AuthAction>;

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'START_AUTHENTICATION': {
      return { ...state, error: null, loading: true };
    }

    case 'ERROR_AUTHENTICATION': {
      const { error } = action;
      console.log('ERROR:', error);
      const parsedError = getError(error);
      console.log('PARSED ERROR:', parsedError);
      return { ...state, error: parsedError, loading: false };
    }

    case 'SET_AUTHENTICATION': {
      const { logname, user } = action;
      const exp = new Date(new Date().getTime() + 3600 * 1000).toString();

      localStorage.setItem('exp', exp);
      localStorage.setItem('logname', logname);
      localStorage.setItem('user', JSON.stringify(user));
  
      if (user)
        LogRocket.identify(String(user.id), {
          name: user.username,
          email: user.email,
        });

      return {
        ...state,
        error: '',
        loading: false,
        isAuthenticated: true,
        logname,
        user,
        exp,
      };
    }
    
    case 'SET_CONTEST': {
      const { contest } = action;
      localStorage.setItem('contest', JSON.stringify(contest));
      
      return {
        ...state,
        loading: false,
        error: null,
        contest,
      };
    }

    case 'SET_CONTESTANT': {
      const { contestant } = action;

      const user = JSON.parse(String(localStorage.getItem('user'))) as User;

      user.contestant_data = contestant
      localStorage.setItem('user', JSON.stringify(user));

      return {
        ...state,
        loading: false,
        error: null,
        user,
      };
    }
  
    case 'SET_USERS': {
      const { users } = action;
      localStorage.setItem('users', JSON.stringify(users || []));
    
      return {
        ...state,
        loading: false,
        users,
      };
    }
    
    case 'SET_RULES': {
      const { rules } = action;
      localStorage.setItem('rules', JSON.stringify(rules || []));

      return {
        ...state,
        loading: false,
        rules,
      };
    }

    case 'SET_LESSONS': {
      const { lessons } = action;
      localStorage.setItem('lessons', JSON.stringify(lessons || []));

      return {
        ...state,
        loading: false,
        lessons,
      };
    }

    case 'SET_LEVELS': {
      const { levels } = action;
      localStorage.setItem('levels', JSON.stringify(levels || []));

      return {
        ...state,
        loading: false,
        levels,
      };
    }
    case 'SET_COMMUNITIES': {
      const { communities } = action;
      localStorage.setItem('communities', JSON.stringify(communities || []));

      return {
        ...state,
        loading: false,
        communities,
      };
    }

    case 'SET_LAKES': {
      const { lakes } = action;
      localStorage.setItem('lakes', JSON.stringify(lakes || []));

      return {
        ...state,
        loading: false,
        lakes,
      };
    }

    case 'SET_DIVISIONS': {
      const { divisions } = action;
      localStorage.setItem('divisions', JSON.stringify(divisions || []));

      return {
        ...state,
        loading: false,
        divisions,
      };
    }

    case 'SET_SPORTS': {
      const { sports } = action;
      localStorage.setItem('sports', JSON.stringify(sports || []));

      return {
        ...state,
        loading: false,
        sports,
      };
    }

    case 'SET_COACHES': {
      const { coaches } = action;
      localStorage.setItem('coaches', JSON.stringify(coaches || []));

      return {
        ...state,
        loading: false,
        coaches,
      };
    }

    case 'SET_CHALLENGES': {
      const { challenges } = action;
      localStorage.setItem('challenges', JSON.stringify(challenges || []));

      return {
        ...state,
        loading: false,
        challenges,
      };
    }

    case 'SET_REPORT_OPTIONS': {
      const { reportOptions } = action;
      localStorage.setItem(
        'reportOptions',
        JSON.stringify(reportOptions || [])
      );

      return {
        ...state,
        reportOptions,
      };
    }

    case 'SET_COMMUNITY': {
      const { community } = action;
      localStorage.setItem('community', JSON.stringify(community));

      return {
        ...state,
        community,
      };
    }

    case 'SET_LAKE': {
      const { lake } = action;
      localStorage.setItem('lake', JSON.stringify(lake));

      return {
        ...state,
        lake,
      };
    }

    case 'SET_DIVISION': {
      const { division } = action;
      localStorage.setItem('division', JSON.stringify(division));

      return {
        ...state,
        division,
      };
    }

    case 'SET_SPORT': {
      const { sport } = action;
      localStorage.setItem('sport', JSON.stringify(sport));

      return {
        ...state,
        sport,
      };
    }

    case 'CLEAR_AUTHENTICATION': {
      localStorage.clear();
      return defaultState();
    }

    case 'RESET_AUTHENTICATION': {
      const logname = localStorage.getItem('logname');

      const user = JSON.parse(
        String(localStorage.getItem('user') || {})
      ) as User;

      const community = JSON.parse(
        String(localStorage.getItem('community') || {})
      ) as Community;

      const lake = JSON.parse(
        String(localStorage.getItem('lake') || {})
      ) as Lake;

      const division = JSON.parse(
        String(localStorage.getItem('division') || {})
      ) as Division;

      const sport = JSON.parse(
        String(localStorage.getItem('sport') || {})
      ) as Sport;
  
      const users = JSON.parse(
        String(localStorage.getItem('users') || [])
      ) as User[];
  
      const rules = JSON.parse(
        String(localStorage.getItem('rules') || [])
      ) as Rule[];

      const lessons = JSON.parse(
        String(localStorage.getItem('lessons') || [])
      ) as Lesson[];

      const levels = JSON.parse(
        String(localStorage.getItem('levels') || [])
      ) as Level[];

      const communities = JSON.parse(
        String(localStorage.getItem('communities') || [])
      ) as Community[];

      const lakes = JSON.parse(
        String(localStorage.getItem('lakes') || [])
      ) as Lake[];

      const divisions = JSON.parse(
        String(localStorage.getItem('divisions') || [])
      ) as Division[];

      const sports = JSON.parse(
        String(localStorage.getItem('sports') || [])
      ) as Sport[];

      const coaches = JSON.parse(
        String(localStorage.getItem('coaches') || [])
      ) as User[];

      const challenges = JSON.parse(
        String(localStorage.getItem('challenges') || [])
      ) as Challenge[];

      const exp = localStorage.getItem('exp') || '';

      return {
        ...state,
        logname,
        user,
        community,
        lake,
        division,
        sport,
        users,
        rules,
        levels,
        lessons,
        communities,
        lakes,
        divisions,
        sports,
        coaches,
        challenges,
        exp,
        isAuthenticated:
          Boolean(logname) || Boolean(localStorage.getItem('logname')),
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
