import * as React from 'react';
import { useAlert } from 'react-alert';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteData, useAuth } from '../../state-management';
import { Post } from '../../state-management/types';
import { ConfirmModal } from '..';
import {ReportForm, AchievementsForm, ContestJudgeForm} from '../../forms';
import './styles.scss';
import {isPrivileged} from '../../utils';

interface PostMenuProps {
  post: Post;
  hidePost: any;
}

const PostMenu: React.FC<PostMenuProps> = ({ post, hidePost }) => {
  const { state, getUser, deletePost } = useAuth();
  
  const alert = useAlert()
  
  const [msg, setMsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (e: any, callback: any) => {
    if (post) {
      setLoading(true);
      deletePost(post).then((res: any) => {
        if (res?.status === 204) {
          const msgText = 'Post deleted'
          setMsg(msgText);
          getUser();
          setTimeout(() => {
            handleClose()
            if (callback) callback()
            if (hidePost) hidePost()
            alert.show(msgText, { type: 'success' })
          }, 500)
        }
        else {
          setMsg('')
          setLoading(false)
        }
      });
    }
  };

  return (
    <div className='lg-post-menu'>
      <IconButton aria-label='extra' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id='post-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: 'lg-post-menu--paper',
          list: 'lg-post-menu--list',
        }}
      >
        
        <MenuItem button className='lg-post-menu--item btn1'>
          <ReportForm post={post} />
        </MenuItem>
  
        {isPrivileged(state?.user) ? (
          <MenuItem button className='lg-post-menu--item btn1'>
            <ContestJudgeForm post={post} />
          </MenuItem>
        ) : null}

        {post?.owner_data?.id === state.user?.id || isPrivileged(state?.user) ? (
          <MenuItem button className='lg-post-menu--item'>
            <AchievementsForm post={post} />
          </MenuItem>
        ) : null}

        {post?.owner_data?.id === state.user?.id || isPrivileged(state?.user) ? (
          <MenuItem button className='lg-post-menu--item'>
            <ConfirmModal
              action='delete this post'
              msg={msg}
              onSubmit={handleDelete}
              errorMsg={state.error}
              loading={loading}
            />
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default PostMenu;
