import * as React from 'react';
import { Paper, ListItem, Checkbox } from '@material-ui/core';
import { Challenge } from '../../state-management/types';
import { TextField } from '..';
import './styles.scss';

interface ChallengesScrollFieldProps {
  buttonText?: string;
  challenges: Challenge[];
  setSelected: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setSelectedDone: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  selectedChallenges?: number[];
  selectedDoneChallenges?: number[];
}

const ChallengesScrollFieldComponent: React.FC<ChallengesScrollFieldProps> = ({
  buttonText,
  challenges,
  setSelected,
  setSelectedDone,
  selectedChallenges,
  selectedDoneChallenges,
}) => {

  const [term, setTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<Challenge[]>();

  React.useEffect(() => {
    setSearchResults(
      challenges?.filter((challenge: Challenge) => challenge.name.toLowerCase())
    );
  }, [challenges]);

  const addChallenge = (challenge: number) => {
    const tempChallenges = selectedChallenges;
    tempChallenges?.push(challenge);
    setSelected(tempChallenges);
  };

  const addChallengeDone = (challenge: number) => {
    const tempChallenges = selectedDoneChallenges;
    tempChallenges?.push(challenge);
    setSelectedDone(tempChallenges);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const challengeID = Number(e.target.value);
    if (e.target.checked) {
      addChallenge(challengeID);
    } else {
      const tempSelected = selectedChallenges || [];

      for (let i = 0; i < tempSelected.length; i += 1) {
        if (tempSelected[i] === challengeID) {
          tempSelected.splice(i, 1);
          setSelected(tempSelected);
          break;
        }
      }
    }
  };

  const handleCheckDone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const challengeID = Number(e.target.value);
    if (e.target.checked) {
      addChallengeDone(challengeID);
    } else {
      const tempSelected = selectedDoneChallenges || [];

      for (let i = 0; i < tempSelected.length; i += 1) {
        if (tempSelected[i] === challengeID) {
          tempSelected.splice(i, 1);
          setSelectedDone(tempSelected);
          break;
        }
      }
    }
  };

  return (
    <div className='lg-scroll-field'>
      <TextField
        value={term}
        id='search-term'
        label={buttonText || 'Search for Tricks in video'}
        onChange={(e) => {
          const lowVal = e.target.value.toLowerCase();
          setTerm(lowVal);
          setSearchResults(
            challenges?.filter((challenge: Challenge) =>
              challenge.name.toLowerCase().includes(lowVal)
            )
          );
        }}
      />

      <div className="check-box-header">Attempted Achieved</div>
      <Paper className='lg-scroll-field--paper'>
        {searchResults?.map((val) => (
          <ListItem
            key={val.id}
            value={val.id}
            className='lg-scroll-field--item'
          >
            {val.name}
            <div className="check-box-parent">
              <Checkbox
                  value={val.id}
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={handleCheck}
                  className="checkbox-margin-right"
              />
              <Checkbox
                  value={val.id}
                  color='secondary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={handleCheckDone}
                  className="checkbox-margin-left"
              />
            </div>
          </ListItem>
        ))}
      </Paper>
    </div>
  );
};

export default ChallengesScrollFieldComponent;
