import * as React from 'react';
import { useAlert } from 'react-alert';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import { useAuth } from '../../state-management';
import { Post } from '../../state-management/types';
import {Message, Loader, TextField, SelectField} from '../../components';
import './styles.scss';

interface ContestEntryFormProps {
  post: Post;
}

const ContestJudgeForm: React.FC<ContestEntryFormProps> = ({ post }) => {
  const { state, updateContestantEntry } = useAuth();
  
  const alert = useAlert()
  
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [msg, setMsg] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [place, setPlace] = React.useState<number>(0);

  const handleSubmit = () => {
    updateContestantEntry(name, place, post).then((res: any) => {
      const msgText = 'Successfully updated contestant entry'
      setMsg(msgText);
      setTimeout(() => {
        setOpen(false)
        alert.show(msgText, { type: 'success' })
      }, 1000)
      setLoading(false);
    });
  };

  const handleClick = () => {
    setOpen(!open);
    setName('');
    setMsg('');
    setLoading(false);
  };

  const title = 'Update Contestant Entry'
  const msgBackup = 'Please add contestant name and podium placement, if necessary'
  
  return (
    <div>
      <div
        onClick={handleClick}
        aria-controls='simple-menu'
        aria-haspopup='true'
      >
        {title}
      </div>
      
      <Dialog
        fullWidth
        open={open}
        onClose={handleClick}
        aria-labelledby='comment-title'
        className='lg-comment-form'
      >
        <DialogTitle
          id='comment-dialog'
          className='lg-comment-form--title'
          disableTypography
        >
          Coaches Feedback
        </DialogTitle>

        <DialogContent className='lg-comment-form--content'>
          <Message severity='error' message={state.error} />
          <Message
            severity={msg ? 'success' : 'info'}
            message={
              msg || msgBackup
            }
          />

          <div>
            <TextField
              value={name}
              id='name'
              label='Name'
              onChange={(e) => setName(e.target.value)}
              // handleSubmit={handleSubmit}
            />
          </div>
          
          <div>
            <SelectField
              value={place}
              label='Place'
              values={[1, 2, 3]}
              setValue={setPlace}
            />
          </div>
        </DialogContent>

        <DialogActions className='lg-comment-form--actions'>
          <Loader loading={loading} />
          <Button
            onClick={handleSubmit}
            color='primary'
            className='lg-comment-form--btn'
            classes={{
              label: 'lg-comment-form--btn-label',
            }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClick}
            color='primary'
            className='lg-comment-form--btn'
            classes={{
              label: 'lg-comment-form--btn-label',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContestJudgeForm;
