import * as React from 'react';
import { TextField } from '@material-ui/core';
import './styles.scss';

interface TextFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<{ value: string }>) => void;
  id: string;
  helperText?: string;
  label?: string;
  type?: string;
  autoComplete?: string;
  shrinkLabel?: boolean;
  handleSubmit?: any;
}

const TextFieldComponent: React.FC<TextFieldProps> = ({
  onChange,
  value,
  id,
  helperText,
  label,
  type = 'text',
  autoComplete = 'text',
  shrinkLabel,
  handleSubmit,
}) => {
  return (
    <div className={`lg-text-field--${id}`}>
      <TextField
        className='lake-games--text'
        color='primary'
        inputProps={{ type, autoComplete }}
        InputProps={{
          className: 'lake-games--text-input',
          classes: {
            notchedOutline: 'lake-games--input-outline',
            focused: 'lake-games--input-focused',
            input: 'lake-games--input',
          },
        }}
        InputLabelProps={{
          shrink: shrinkLabel,
          className: 'lake-games--text-label',
          classes: {
            outlined: 'lake-games--text-label-outlined',
            focused: 'lake-games--label-focused',
          },
        }}
        id={id}
        fullWidth
        label={label}
        onChange={onChange}
        value={value}
        helperText={helperText}
        variant='outlined'
        // onKeyPress={(e) => {
        //   if (e.key === 'Enter') handleSubmit(e);
        // }}
      />
    </div>
  );
};

export default TextFieldComponent;
