import * as React from 'react'
import { useAlert } from 'react-alert';
import { Button, Checkbox } from '@material-ui/core';
import { useAuth } from '../../state-management'
import { User, Community, Lake } from '../../state-management/types'
import {
  Message,
  Loader,
  SelectField,
  DialogBox,
  TextField ,
} from '../../components'
import './styles.scss'

interface ContestantFormProps {
  user?: User
}

const ContestantForm: React.FC<ContestantFormProps> = ({ user }) => {
  const { state, createContestant, getCommunities, getLakes } = useAuth()
  
  const alert = useAlert()
  
  const [msg, setMsg] = React.useState<string>('')
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const [community, setCommunity] = React.useState<Community>()
  const [lake, setLake] = React.useState<Lake>()
  const [address, setAddress] = React.useState<string>('')
  const [birthday, setBirthday] = React.useState('')
  const [gender, setGender] = React.useState<string>('')
  const [isPrivate, setIsPrivate] = React.useState<boolean>(false)
  const genders = ['Male', 'Female']

  React.useEffect(() => {
    getCommunities().catch((e) => {
      console.log(e)
    })
  }, []) // eslint-disable-line

  React.useEffect(() => {
    getLakes(community || null).catch((e) => {
      console.log(e)
    })
  }, [community]) // eslint-disable-line

  const handleSubmit = () => {

    // if (!community) setError('Please choose community')
    // if (!lake) setError('Please choose lake')
    if (!address) setError('Please enter lake address to prove residency in the area')
    else if (!birthday) setError('Please enter birthday')
    else if (!gender) setError('Please choose gender to participate with')
    else {
      setLoading(true)
      createContestant(user, community, lake, address, birthday, gender, isPrivate)
      .then((res: any) => {
          if (res?.status === 201) {
            const msgText = 'Successfully created contestant account'
            setMsg(msgText)
            setError('')
            alert.show(msgText, { type: 'success' })
          }
          setLoading(false)
        }
      )
    }
  }

  const errorMsg = error || state.error

  return (
    <DialogBox title='Upload Contestant'>
      <Loader loading={loading} />
      <Message
        severity={errorMsg ? 'error' : 'info'}
        message={errorMsg || 'Must fill out to upload videos'}
      />
      <Message severity='success' message={msg} />
      <br />
      <SelectField
        value={lake}
        label='Lake'
        required
        showOther
        values={state.lakes}
        setValue={setLake}
      />

      <TextField
        value={address}
        id='address'
        label='Lake Address'
        autoComplete='address'
        onChange={(e) => setAddress(e.target.value)}
        // handleSubmit={handleSubmit}
      />

      <TextField
        value={birthday}
        shrinkLabel
        id='birthday'
        type='date'
        label='Birthday'
        autoComplete='birthday'
        onChange={(e) => setBirthday(e.target.value)}
      />

      <SelectField
        value={gender}
        label='Gender participation'
        required
        values={genders}
        setValue={setGender}
      />

      <div>
        Make account private
        <Checkbox
          value={isPrivate}
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={(e) => {
            if (e.target.checked) setIsPrivate(true)
            else setIsPrivate(false)

          }}
        />
      </div>

      <Button variant='outlined' color='primary' onClick={handleSubmit}>
        <div className='contestant-submit'>Submit</div>
      </Button>
    </DialogBox>
  )
}

export default ContestantForm

// <SelectField
// label='Community'
// required
// value={community}
// values={state.communities}
// setValue={setCommunity}
// />

