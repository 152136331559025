import * as React from 'react'
import { useAlert } from 'react-alert';
import {Button, Checkbox, Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import ProgressBar from '@ramonak/react-progress-bar';
import {patchData, useAuth, useUploads} from '../../state-management'
import {Lesson, Sport, Challenge, User, ContestantEntry} from '../../state-management/types'
import {
  Message,
  Loader,
  SelectField,
  ChallengesScrollField,
  FileField, TextField,
} from '../../components'
import './styles.scss'

interface VideoFormProps {
  user?: User
  contestantEntry?: ContestantEntry
  sportIn?: Sport
  lesson?: Lesson
  open?: boolean
  callback?: any
  allowComment?: boolean
  removeButton?: boolean
  closeAfterCreate?: boolean
}

const VideoForm: React.FC<VideoFormProps> = ({lesson, user, contestantEntry, sportIn, open, callback, allowComment, removeButton, closeAfterCreate}) => {
  const {state, getSports, getChallenges, uploadVideo, uploadComment, getUser} = useAuth()
  const uploads = useUploads()
  
  const alert = useAlert()
  
  const [isOpen, setIsOpen] = React.useState<boolean>(open || false)
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [msg, setMsg] = React.useState<string>('')
  
  const [sport, setSport] = React.useState<Sport>()
  const [isPrivate, setIsPrivate] = React.useState<boolean>(Boolean(state.user?.contestant_data?.is_private))
  const [comment, setComment] = React.useState<string>('')
  const [challenges, setChallenges] = React.useState<Challenge[]>([])
  const [selectedChallenges, setSelected] = React.useState<number[] | undefined>([])
  const [selectedDoneChallenges, setSelectedDone] = React.useState<number[] | undefined>([])
  const [file, setFile] = React.useState<File>()
  
  const [uploadId, setUploadId] = React.useState<number>(-1)
  
  React.useEffect(() => {
    getSports()
  }, []) // eslint-disable-line
  
  React.useEffect(() => {
    const currentSport = getCurrentSport();
    getChallenges(currentSport || null).then((challengesList) => {
      setChallenges(challengesList)
    })
    setSelected([])
    setSelectedDone([])
  }, [sport]) // eslint-disable-line
  
  const getCurrentSport = () => {
    if (sportIn) return sportIn
    return sport
  }
  
  const handleSubmit = async () => {
    const currentSport = getCurrentSport();
    setLoading(true)
    setMsg('')
    setError('')
    
    if (!file) {
      setLoading(false)
      setError('Please select video file to upload')
      
    } else if (!currentSport)
      setError('Please select sport')
      
    else {
      setError('')
      const res = await uploadVideo(lesson, user, contestantEntry, currentSport, file, isPrivate, selectedChallenges || [], selectedDoneChallenges || [])
      if (res?.status === 201) {
        if (closeAfterCreate) {
          setIsOpen(false)
          clearForm()
        } else {
          setUploadId(res.data?.id)
        }
        
        const userId = contestantEntry?.user_id || state.user?.id || 0
        const uploadRes = await uploads.uploadFile(userId, res.data?.id, file, res.data?.upload_url)
        if (allowComment && comment) uploadComment(res?.data, user, comment)
        
        if (callback) callback(res.data)
        else getUser()
        
        if (uploadRes.status === 201) {
          
          const patchRes = await patchData(`posts/${res.data?.id}/update_file`, {
            filename: res.data?.file_destination
          })
          
          if (!closeAfterCreate && patchRes.status === 200) {
            const msgText = 'Successfully posted video'
            setMsg(msgText)
            setTimeout(() => {
              setLoading(false)
              alert.show(msgText, {type: 'success'})
            }, 500)
          }
        } else {
          setError(uploadRes.error)
        }
      }
    }
  }
  
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  
  const handleClose = () => {
    clearForm()
    setIsOpen(false);
  }
  
  const clearForm = () => {
    setFile(undefined)
    setSport(undefined)
    setIsPrivate(Boolean(state.user?.contestant_data?.is_private))
    setMsg('')
    setError('')
    setComment('')
    setSelected([])
    setSelectedDone([])
    setLoading(false)
    setUploadId(-1)
  }
  
  const title = 'Upload Video'
  let dialogTitle = title
  if (contestantEntry)
    dialogTitle = `${title} for Contestant ${contestantEntry.first_name} ${contestantEntry.last_name}`
  
  const errorMsg = error || state.error
  return (
    <div className='video-form-dialog'>
      {!removeButton ? (
        <Button
          className='dialog-box--btn'
          variant='outlined'
          color='primary'
          onClick={handleOpen}
          classes={{label: 'dialog-box--btn-label'}}
        >
          <div>{title}</div>
        </Button>
      ) : <div onClick={handleOpen}>{title}</div>}
      <Dialog
        onClose={handleClose}
        className='dialog-box'
        aria-labelledby='simple-dialog-title'
        open={isOpen}
        fullWidth
      >
        <DialogTitle
          disableTypography
          className='dialog-box--title'
          id='simple-dialog-title'
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent className='dialog-box--content'>
          <div className='lg-video-form'>
            
            {!sportIn ? (
              <SelectField
                label='Sport'
                required
                value={sport}
                values={state.sports}
                setValue={setSport}
              />
            ) : null}
            
            {sport || sportIn ? (
              <ChallengesScrollField
                buttonText='Search challenges'
                challenges={challenges}
                selectedChallenges={selectedChallenges}
                selectedDoneChallenges={selectedDoneChallenges}
                setSelected={setSelected}
                setSelectedDone={setSelectedDone}
              />
            ) : null}
            
            <FileField
              label='Upload Video'
              required
              setValue={setFile}
            />
            
            {allowComment ? (
              <TextField
                value={comment}
                id='feedback'
                label='Feedback'
                autoComplete='feedback'
                onChange={(e) => setComment(e.target.value)}
                handleSubmit={handleSubmit}
              />
            ) : null}
            
            <div className='is--private'>
              Is Private
              <Checkbox
                checked={isPrivate}
                value={isPrivate}
                color='primary'
                inputProps={{'aria-label': 'secondary checkbox'}}
                onChange={(e) => {
                  if (e.target.checked) setIsPrivate(true)
                  else setIsPrivate(false)
                }}
              />
            </div>
            
            <Message
              severity={errorMsg ? 'error' : 'success'}
              message={errorMsg || msg}
            />
            
            <br/>
            
            {uploadId > 0 && (
              <ProgressBar bgColor='limegreen' completed={uploads.state.uploads[uploadId]?.progress}/>
            )}
            
            <Loader loading={loading}/>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleSubmit}
              className='lg-video-form--btn'
              classes={{
                label: 'lg-video-form--btn-label',
              }}
            >
              Submit
            </Button>
          </div>
          <br/>
          <Button
            className='dialog-box--btn'
            variant='outlined'
            color='primary'
            onClick={handleClose}
            classes={{label: 'dialog-box--btn-label'}}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default VideoForm
