import * as React from 'react';
import {Link} from 'react-router-dom';
import { Comment } from '../../state-management/types';
import {Avatar} from '..';
import './styles.scss';
import {getTimeDiff} from '../../utils';


interface CommentProps {
  comment: Comment
}

const CommentComponent: React.FC<CommentProps> = ({ comment }) => {

  console.log('COMMENT', comment)
  
  
  return (

    <div className="be-comment">
    <div className="be-img-comment">	
      <Link to={`/user/${comment?.owner_data?.username}`}>
        <Avatar user={comment?.owner_data} />
      </Link>
    </div>
    <div className="be-comment-content">
      <span className="be-comment-name">
        <Link to={`/user/${comment?.owner_data?.username}`}>
          {comment?.owner_data?.username}
        </Link>
      </span>
      <span className="be-comment-time">
        <i className="fa fa-clock-o"></i>
        {getTimeDiff(comment.created)}
      </span>
      <p className="be-comment-text">
      {comment.text}
      </p>
    </div>
  </div>
    // <div className="comment mt-4 text-justify float-left">
    //  <Container> 
    //   <Row>
    //     <Col md={4}>
    //     <Avatar user={comment?.owner_data} />
    //     </Col>
    //     <Col md={4}>
    //     <div className='ava-text'>{comment?.owner_data?.username}</div>
    //     </Col>
    //     <Col md={4}>
    //     <span>- 20 October, 2018</span>
    //     </Col>
    //   </Row>
    //   <Row>
    //       <p>{comment.text}</p>
    //   </Row>
    // </Container>
    // </div>
    
  );
};

export default CommentComponent;
