import * as React from 'react';
import { useAlert } from 'react-alert';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
} from '@material-ui/core';
import { useAuth } from '../../state-management';
import { Community, Lake } from '../../state-management/types';
import {
  Loader,
  Message,
  FileField,
  TextField,
  SelectField,
} from '../../components';
import './styles.scss';


const EditProfileForm: React.FC = () => {
  const {
    state,
    getCommunities,
    getLakes,
    changeUsername,
    uploadProfile,
    updateContestant,
    getUser,
  } = useAuth();
  
  const alert = useAlert()
  
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [username, setUsername] = React.useState(String(state.user?.username));
  const [community, setCommunity] = React.useState<Community>();
  const [lake, setLake] = React.useState<Lake>();
  const [file, setFile] = React.useState<File>();
  const [address, setAddress] = React.useState<string>('');
  const [isPrivate, setIsPrivate] = React.useState<boolean>(Boolean(state.user?.contestant_data?.is_private));
  const [birthday, setBirthday] = React.useState(String(state.user?.contestant_data?.birthday));
  const [gender, setGender] = React.useState<string>(capitalize(String(state.user?.contestant_data?.gender)));
  const genders = ['Male', 'Female'];
  const [boat, setBoat] = React.useState<string>(state.user?.contestant_data?.boat || '');
  const [board, setBoard] = React.useState<string>(state.user?.contestant_data?.board || '');

  function capitalize(str: string) : string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  React.useEffect(() => {
    getCommunities();
  }, []); // eslint-disable-line

  React.useEffect(() => {
    getLakes(community || null);
    setLake(undefined);
  }, [community]); // eslint-disable-line

  const handleClick = () => {
    setOpen(!open);
    if (open) handleClose()
  };
  
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setMsg('');
    setFile(undefined);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (file)
      uploadProfile(file).then((res: any) => {
        if (res?.status === 201) {
          const msgText = 'Successfully updated contestant'
          setMsg(msgText);
          setTimeout(() => {
            handleClick()
            getUser()
            alert.show(msgText, { type: 'success' })
          }, 1000)
        }
      });

    if (username)
      changeUsername(username).then((res: any) => {
        if (res?.status === 200) {
          const msgText = 'Successfully updated username'
          setMsg(msgText);
          setTimeout(() => {
            handleClick()
            getUser()
            alert.show(msgText, { type: 'success' })
          }, 1000)
        }
      });

    const contestant = state.user?.contestant_data;

    if (contestant) {
      if (community && lake) {
        contestant.community_data = community;
        contestant.lake_data = lake;
      }

      if (address) contestant.address = address;


      if (birthday) contestant.birthday = birthday;

      if (gender) contestant.gender = gender;

      if (boat) {
        contestant.boat = boat;
      }

      if (board) {
        contestant.board = board;
      }


      let changeIsPrivate = false
      if (isPrivate !== contestant?.is_private) {
        contestant.is_private = isPrivate;
        changeIsPrivate = true;
      }

      if (state.user && (community || lake || address || birthday || gender || boat || board || changeIsPrivate))
        updateContestant(contestant, state.user).then((res: any) => {
          const msgText = 'Successfully updated contestant'
          setMsg(msgText);
          setTimeout(() => {
            getUser()
            handleClick()
            alert.show(msgText, { type: 'success' })
          }, 1000)
        });
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        aria-controls='change-password-dialog'
        aria-haspopup='true'
      >
        Edit Profile
      </div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClick}
        aria-labelledby='edit-profile'
        className='lg-edit-profile'
      >
        <DialogTitle
          id='edit-profile'
          disableTypography
          className='lg-edit-profile--title'
        >
          Edit Profile
        </DialogTitle>

        <DialogContent className='lg-edit-profile--content'>
          <Message severity='error' message={state.error} />
          <TextField
            value={username}
            id='username'
            label='Change username'
            onChange={(e) => setUsername(e.target.value)}
            handleSubmit={handleSubmit}
          />

          <FileField label='Profile picture' required setValue={setFile} />

          {state.user?.contestant_data ? (
            <>
              {false ? (
                <SelectField
                  label='Community'
                  value={community}
                  values={state.communities}
                  setValue={setCommunity}
                />
              ) : null}

              <SelectField
                label='Lake'
                value={lake}
                values={state.lakes}
                setValue={setLake}
              />

              <TextField
                value={address}
                id='address'
                label='Lake Address'
                autoComplete='address'
                onChange={(e) => setAddress(e.target.value)}
                // handleSubmit={handleSubmit}
              />

              <TextField
                value={birthday}
                shrinkLabel
                id='birthday'
                type='date'
                label='Birthday'
                autoComplete='birthday'
                onChange={(e) => setBirthday(e.target.value)}
              />

              <SelectField
                value={gender}
                label='Gender participation'
                required
                values={genders}
                setValue={setGender}
              />

              <TextField
                value={boat}
                id='boat'
                label='Boat'
                autoComplete='boat'
                onChange={(e) => setBoat(e.target.value)}
                handleSubmit={handleSubmit}
              />

              <TextField
                value={board}
                id='board'
                label='Board/Ski'
                autoComplete='board'
                onChange={(e) => setBoard(e.target.value)}
                handleSubmit={handleSubmit}
              />

              <div>
                Is Private
                <Checkbox
                  checked={isPrivate}
                  value={isPrivate}
                  color='primary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={(e) => {
                    if (e.target.checked) setIsPrivate(true)
                    else setIsPrivate(false)
                  }}
                />
              </div>

            </>
          ) : null}
        </DialogContent>

        <DialogActions className='lg-edit-profile--actions'>
          <Message
            severity={state.error ? 'error' : 'success'}
            message={state.error || msg}
          />
          <Loader loading={loading} />
          <Button
            onClick={handleSubmit}
            color='primary'
            className='lg-edit-profile--btn'
            classes={{ label: 'lg-edit-progress-card--btn-label' }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClick}
            color='primary'
            className='lg-edit-profile--btn'
            classes={{ label: 'lg-edit-progress-card--btn-label' }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProfileForm;
