import * as React from 'react'
import {
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  Collapse,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Row from 'react-bootstrap/Row';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VideoImageThumbnail from 'react-video-thumbnail-image'; // use npm published version
import {Avatar, LessonMenu, CommentComponent} from '..';
import {Comment, Lesson, Post} from '../../state-management/types'
import {CURRENT_URL, getTimeDiff, LIFECYCLE} from '../../utils';
import './styles.scss'


interface LessonProps {
  lesson: Lesson
}


const LessonComponent: React.FC<LessonProps> = ({ lesson }) => {

  const [expanded, setExpanded] = React.useState(true);
  const [post, setPost] = React.useState<Post>();

  if (!post && lesson?.posts_data?.length > 0) setPost(lesson.posts_data[0])

  const getFullFilename = (postInput: Post | undefined) => {
    let ext = 'first-tantrum.mov';

    if (postInput?.filename) ext = postInput?.filename

    if (LIFECYCLE === 'local') return CURRENT_URL + ext
    return postInput?.filename;
  };

  return (
      <>
        <div className="vid-main-wrapper clearfix">

          <div className="vid-container">
            <Card className='vid_frame'>

              <CardHeader
                  className='lg-post--header'
                  avatar={<Avatar user={post?.owner_data} />}
                  action={
                    <>
                      {post ? (
                          <span className="be-comment-time">
                      <i className="fa fa-clock-o"></i>
                            {getTimeDiff(post?.created)}
                    </span>
                      ) : null}
                      <LessonMenu lesson={lesson} user={post?.owner_data} currentPost={post} />
                    </>
                  }
                  title={
                    <div className='lg-post--header-title'>
                      {post?.owner_data?.username}
                    </div>
                  }
                  subheader={
                    <div className='lg-post--header-subtitle'>
                      {post?.sport_data?.name}
                    </div>
                  }
              />
              <CardMedia
                  component='video'
                  controls
                  src={getFullFilename(post)}
              />

              <CardActions className='lg-post--actions'>
                <IconButton
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label='show comments'
                >
                  {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout='auto' unmountOnExit>
                <div className="be-comment-block">
                  <h1 className="comments-title">Comments ({post?.comments_data?.length || 0 })</h1>
                  {post?.comments_data?.map((commentItem: Comment) => (
                      <CommentComponent comment={commentItem}/>
                  ))}
                </div>
              </Collapse>
            </Card>
          </div>

          <div className="vid-list-container">
            <ol id="vid-list">
              {lesson?.posts_data?.map((postItem: Post, index: number) => (
                  <li key={index}  onClick={() => setPost(postItem)} className={postItem.id === post?.id ? 'bg-grey' : '' } >
                    <div className="desc">Video {index}</div>
                  </li>
              ))}
            </ol>
          </div>
        </div>
      </>
  )
}

export default LessonComponent


// <VideoImageThumbnail
// videoUrl={getFullFilename(postItem)}
// width={75}
// height={80}
// alt="my test video"
//   />

