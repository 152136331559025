import * as React from 'react';
import {Link} from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardMedia,
    CardActions,
    Collapse,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {Post, Achievement} from '../../state-management/types';
import {Avatar, LikesBar, Medal, PostMenu, TrickBadge} from '..';
import {getTimeDiff} from '../../utils';
import './styles.scss';

interface PostProps {
    key: number | null;
    hideHeader?: boolean;
    post: Post;
    place?: 'first' | 'second' | 'third';
}

const PostComponent: React.FC<PostProps> = ({post, place, hideHeader}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [hidden, setHidden] = React.useState<boolean>(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    
    const placeStr = place ?? post.place_str

    if (hidden) return null

    return (
        <Card className='lg-post'>
            {!hideHeader ? (
                <CardHeader
                    className='lg-post--header'
                    avatar={<Avatar user={post?.owner_data || undefined}/>}
                    action={
                        <>
                            <Medal place={placeStr} />
                            {post ? (
                                <span className="be-comment-time">
                                    <i className="fa fa-clock-o"/>
                                    {getTimeDiff(post?.created)}
                                </span>
                            ) : null}
                            <PostMenu post={post} hidePost={() => setHidden(true)}/>
                        </>
                    }
                    title={
                        <Link to={`/user/${post?.owner_data?.username}`}>
                            <div className='lg-post--header-title'>
                                {post?.owner_data?.username}
                            </div>
                        </Link>
                    }
                />
            ) : null}

            <CardMedia
              component='video'
              controls
              autoPlay={false}
              src={String(post.filename)}
            />

            <CardActions disableSpacing className='lg-post--actions'>
                <LikesBar postID={post.id} likes={post.likes_data}/>
                {post.achievements?.length > 1 ? (
                    <>
                        <IconButton
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label='show more'
                        >
                            {!expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                        </IconButton>
                    </>
                ) : null}
            </CardActions>
          <div className='achievements-collapsible'>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
                {post.achievements?.map((achievement: Achievement) => (
                    <TrickBadge
                        key={achievement.id}
                        owner={post.owner_data || undefined}
                        challenge={achievement.challenge_data}
                    />
                ))}
            </Collapse>
          </div>
        </Card>
    );
};

export default PostComponent;
