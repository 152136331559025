import * as React from 'react'
import { useAlert } from 'react-alert';
import { Button, Checkbox } from '@material-ui/core';
import { useAuth } from '../../state-management'
import { User, Community, Lake } from '../../state-management/types'
import {
  Message,
  Loader,
  SelectField,
  DialogBox,
  TextField ,
} from '../../components'
import './styles.scss'

interface LakeFormProps {
  user?: User
}

const ContestantForm: React.FC<LakeFormProps> = ({ user }) => {
  const { state, createLake, getCommunities } = useAuth()
  
  const alert = useAlert()
  
  const [msg, setMsg] = React.useState<string>('')
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const [community, setCommunity] = React.useState<Community>()
  const [name, setName] = React.useState<string>('')
  const [city, setCity] = React.useState<string>('')
  const [stateString, setStateString] = React.useState<string>('')
  const [zipcode, setZipcode] = React.useState<string>('')
  const [lat, setLat] = React.useState<string>('')
  const [lng, setLng] = React.useState<string>('')

  React.useEffect(() => {
    getCommunities().catch((e) => {
      console.log(e)
    })
  }, []); // eslint-disable-line

  
  const handleSubmit = () => {
    if (!community) setError('Please choose community')
    else {
      setLoading(true)
      createLake(name, city, stateString, zipcode, community, lat, lng)
      .then((res: any) => {
        if (res?.status === 201) {
          const msgText = 'Successfully submitted lake'
          setMsg(msgText)
          setError('')
          alert.show(msgText, { type: 'success' })
        }
        setLoading(false)
      })
    }
  }

  const errorMsg = error || state.error

  return (
    <DialogBox className='add-lake-dialog' title='Add lake'>
      <Loader loading={loading} />
      <Message
        severity={errorMsg ? 'error' : 'info'}
        message={errorMsg || 'Provide either lake address or lat/lng'}
      />
      <Message severity='success' message={msg} />
      <br />
      <div className='lake-games--lake-create'>
        <SelectField
          value={community}
          label='Community'
          required
          values={state.communities}
          setValue={setCommunity}
        />
    
        <TextField
          value={name}
          id='name'
          label='Lake Name'
          autoComplete='lake'
          onChange={(e) => setName(e.target.value)}
          // handleSubmit={handleSubmit}
        />
  
        <TextField
          value={city}
          id='city'
          label='City'
          autoComplete='city'
          onChange={(e) => setCity(e.target.value)}
          // handleSubmit={handleSubmit}
        />
  
        <TextField
          value={stateString}
          id='state'
          label='State'
          autoComplete='state'
          onChange={(e) => setStateString(e.target.value)}
          // handleSubmit={handleSubmit}
        />
  
        <TextField
          value={zipcode}
          id='zipcode'
          label='Zipcode'
          autoComplete='zipcode'
          onChange={(e) => setZipcode(e.target.value)}
          // handleSubmit={handleSubmit}
        />
    
        <TextField
          value={lat}
          id='lat'
          label='Latitude'
          autoComplete='lat'
          onChange={(e) => setLat(e.target.value)}
          // handleSubmit={handleSubmit}
        />
    
        <TextField
          value={lng}
          id='lng'
          label='Longitude'
          autoComplete='lng'
          onChange={(e) => setLng(e.target.value)}
          // handleSubmit={handleSubmit}
        />
      </div>

      <Button variant='outlined' color='primary' onClick={handleSubmit}>
        <div className='lake-submit'>Submit</div>
      </Button>
    </DialogBox>
  )
}

export default ContestantForm
