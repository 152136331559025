import * as React from 'react';
import {publicBucketUrl} from '../../utils';
import './styles.scss';

interface MedalProps {
    place?: string
}

const Medal: React.FC<MedalProps> = ({place}) => {
    const url = `${publicBucketUrl}/medals/${place}.png`;
    
    if (place)
        return (
          <img height={50} width={40} src={url} alt={place} className='medal__icon'/>
        );
    
    return null
};

export default Medal;
