import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Shaka } from '../../components';
import { useAuth } from '../../state-management';
import { Division, Level, Rule } from '../../state-management/types';
import './styles.scss';

const Rules: React.FC<RouteComponentProps> = () => {
  const { state, getRules, getLevels, getDivisions } = useAuth();

  const formatStr = (div: Division) => {
    if (div?.name?.includes('Masters')) return '30+';
    return `${div.lower_bound} - ${div.upper_bound}`;
  };

  const rulesComponent = (
    <div className='lg-rules'>
      <div className='lg-rules--header'>Rules/FAQ</div>
      {state.rules?.map((rule: Rule) => (
        <Accordion key={rule.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='rules-content'
            id='rules-header'
          >
            <> {rule.title} </>
          </AccordionSummary>

          <AccordionDetails>
            {rule.id === 1 ? (
              <p>
                {rule.description}
                <a
                  href='https://www.groovypost.com/howto/add-a-website-shortcut-to-your-iphone-home-screen'
                  target='_blank'
                >
                  here
                </a>
              </p>
            ) : (
              <> {rule.description} </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );

  const divisionsComponent = (
    <div className='lg-rules'>
      <div className='lg-rules--header'>Divisions</div>

      <TableContainer component={Paper}>
        <Table aria-label='divisions table' className='lg-rules--table'>
          <TableHead className='lg-rules--table-head'>
            <TableRow className='lg-rules--table-row'>
              <TableCell className='lg-rules--table-cell'>Divisions</TableCell>
              <TableCell className='lg-rules--table-cell' align='right'>
                Ages
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='lg-rules--table-body'>
            {state.divisions?.map((div: Division) => (
              <TableRow key={div.id} className='lg-rules--table-row'>
                <TableCell
                  component='th'
                  scope='row'
                  className='lg-rules--table-cell'
                >
                  {div.name}
                </TableCell>
                <TableCell align='right' className='lg-rules--table-cell'>
                  {formatStr(div)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const levelsComponent = (
    <div className='lg-rules'>
      <div className='lg-rules--header'>Levels</div>

      <TableContainer component={Paper}>
        <Table aria-label='divisions table' className='lg-rules--table'>
          <TableHead className='lg-rules--table-head'>
            <TableRow className='lg-rules--table-row'>
              <TableCell className='lg-rules--table-cell'>
                Rider Levels
              </TableCell>
              <TableCell align='right' className='lg-rules--table-cell'>
                Color
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='lg-rules--table-body'>
            {state.levels?.map((level: Level) => (
              <TableRow key={level.stage} className='lg-rules--table-row'>
                <TableCell
                  component='th'
                  scope='row'
                  className='lg-rules--table-cell'
                >
                  {level.description}
                </TableCell>
                <TableCell align='right' className='lg-rules--table-cell'>
                  <Shaka color={level.color} numLikes={level.stage} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  React.useEffect(() => {
    getRules().catch((error) => {
      console.log(error);
    });
    getDivisions().catch((error) => {
      console.log(error);
    });
    getLevels().catch((error) => {
      console.log(error);
    });
  }, []); // eslint-disable-line

  return (
    // @ts-ignore
    <Grid
      items={[
        {
          id: 'lg-rules',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: rulesComponent,
          rows: 1,
        },
        {
          id: 'lg-levels',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: levelsComponent,
          rows: 1,
        },
        {
          id: 'lg-divisions',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: divisionsComponent,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Rules);
