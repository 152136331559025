import * as React from 'react';
import Geocode from 'react-geocode';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Map } from '..';
import { Lake } from '../../state-management/types';
import {MAPS_KEY} from '../../utils';
import './styles.scss';


Geocode.setApiKey(MAPS_KEY);


interface LakeProps {
  lake?: Lake;
}

const LakeComponent: React.FC<LakeProps> = ({ lake }) => {
  const [geocodeRes, setGeocodeRes] = React.useState<any>()
  
  React.useEffect(() => {
    Geocode.fromAddress(lake?.geocode_search || '')
      .then((response: any) => {
        const { location } = response.results[0].geometry
        console.log('GEOCODE RESULT:', location)
        setGeocodeRes(location)
      }, (error: any) => {
        console.log('GEOCODE ERROR:', error)
        return error
      }
    );
  }, [lake])
  
  console.log('lake:', lake)
  
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        {false ? <Map lat={lake?.latitude} lng={lake?.longitude} /> : null}
        <Map lat={geocodeRes?.lat} lng={geocodeRes?.lng} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {lake?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {lake?.geocode_search}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default LakeComponent;
