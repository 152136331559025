import React from 'react';
import LogRocket from 'logrocket';
import BaseRouter from './utils/router';
import { Layout } from './components';
import {LIFECYCLE} from './utils';
import './style/main.scss';

if (LIFECYCLE ==='prod') LogRocket.init('n0phks/prod-lakegames');

declare global {
  interface Window {
    LIFECYCLE: string,
    API_URL: string;
    APP_CODE: string;
    BACKEND_BUCKET: string;
    PUBLIC_BUCKET: string;
    MAPS_KEY: string;
  }
}

const App: React.FC = () => (
  <div className='lake-games'>
    <Layout>
      <BaseRouter />
    </Layout>
  </div>
);

export default App;
