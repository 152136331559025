import { createContext } from 'react';
import { UploadsDispatch } from './reducer';

export interface Upload {
  userId: number;
  file: File;
  chunkSize: number;
  uploadUrl: string;
  progress: number;
  error?: string;
}

export interface UploadsState {
  uploads: {
    [key: string]: Upload;
  }
  error: any;
  loading: boolean;
}

export interface UploadsContextState {
  state: UploadsState;
  dispatch: UploadsDispatch;
}

export const defaultState = (): UploadsState => ({
  uploads: {},
  error: '',
  loading: false,
});

export const UploadsContext = createContext<UploadsContextState>({
  state: defaultState(),
  dispatch: () => null,
});
