import * as React from 'react';
import { publicBucketUrl } from '../../utils';
import './styles.scss';

interface ShakaProps {
  color: string | 'gray';
  numLikes: number;
}

const Shaka: React.FC<ShakaProps> = ({ color, numLikes }) => {
  return (
    <div className='shaka-single'>
      <img
        height={45}
        width={50}
        src={`${publicBucketUrl}/thumbs/${color}.png`}
        alt='N/A'
      />
      <div className='shaka-count'>{numLikes}</div>
    </div>
  );
};

export default Shaka;
