import * as React from 'react'
import { useAlert } from 'react-alert';
import {Button, Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import {useAuth} from '../../state-management'
import {ContestantEntry} from '../../state-management/types'
import {
  Message,
  Loader,
  SelectField,
} from '../../components'
import './styles.scss'

interface EditContestantEntryPlaceFormProps {
  contestantEntry?: ContestantEntry
}

const EditContestantEntryPlaceForm: React.FC<EditContestantEntryPlaceFormProps> = ({contestantEntry }) => {
  const {state, editContestantEntryPlace} = useAuth()
  
  const alert = useAlert()
  
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [msg, setMsg] = React.useState<string>('')
  
  const [place, setPlace] = React.useState<number>()
  
  const handleSubmit = () => {
    
    setLoading(true)
    setError('')
    editContestantEntryPlace(contestantEntry, place).then((res: any) => {
      if (res?.status === 200) {
        const msgText = 'Successfully updated contestant place'
        setMsg(msgText)
        setTimeout(() => {
          handleClick()
          alert.show(msgText, { type: 'success' })
        }, 500)
      } else {
        setError(res.data?.detail)
      }
    })
  
  }
  
  const handleClick = () => {
    if (isOpen) clearForm()
    setIsOpen(!isOpen);
  };
  
  const clearForm = () => {
    setPlace(undefined)
    setMsg('')
    setError('')
    setLoading(false)
  }
  
  const title = 'Edit Contestant Place'
  const errorMsg = error || state.error
  const places = [
    0,
    1,
    2,
    3,
  ]
  
  return (
    <div className='video-form-dialog'>
      <div onClick={handleClick}>{title}</div>
      <Dialog
        onClose={handleClick}
        className='dialog-box'
        aria-labelledby='simple-dialog-title'
        open={isOpen}
        fullWidth
      >
        <DialogTitle
          disableTypography
          className='dialog-box--title'
          id='simple-dialog-title'
        >
          Edit Contestant Entry Place
        </DialogTitle>
        <DialogContent className='dialog-box--content'>
          <div className='lg-video-form'>
            
            <SelectField
              label='Place'
              required
              value={place}
              values={places}
              setValue={setPlace}
            />
            
            <Message
              severity={errorMsg ? 'error' : 'success'}
              message={errorMsg || msg}
            />
            
            <Loader loading={loading}/>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleSubmit}
              className='lg-video-form--btn'
              classes={{
                label: 'lg-video-form--btn-label',
              }}
            >
              Submit
            </Button>
          </div>
          <br/>
          <Button
            className='dialog-box--btn'
            variant='outlined'
            color='primary'
            onClick={handleClick}
            classes={{label: 'dialog-box--btn-label'}}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default EditContestantEntryPlaceForm
