import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import {Score, Sport, User} from '../../state-management/types';
import {publicBucketUrl} from '../../utils';
import './style.scss';


interface ProgressCardProps {
  user: User
  sport?: Sport
  progressColor?: string
}

const ProgressCard: React.FC<ProgressCardProps> = ({user, sport, progressColor }) => {
  
  const sportName = sport?.name?.toLocaleLowerCase() || 'wakeboarding'
  const color = user.contestant_data?.level_data?.color;
  const nextColor = user.contestant_data?.next_level_data?.color;
  const gender = user.contestant_data?.gender || 'male';
  const image = `${color}-${sportName}-${gender}`;
  const nextImage = `${nextColor}-${sportName}-${gender}`;
  
  const levelUrl = `${publicBucketUrl}/icons/${sportName}/${color}/${image}.png`;
  const nextLevelUrl = `${publicBucketUrl}/icons/${sportName}/${nextColor}/${nextImage}.png`;
  const pointsNeeded = Number(user.contestant_data?.next_level_data?.min_points) - Number(user.contestant_data?.scores_data?.total_score) || null
  
  const progressPercentage = Math.round(100 * (Number(user.contestant_data?.scores_data?.total_score) / Number(user.contestant_data?.next_level_data?.min_points)))
  
  const getColor = () => {
    if (progressColor === 'auto') {
      if (progressPercentage >= 66) return 'limegreen'
      if (progressPercentage >= 33) return 'orange'
      return 'red'
    }
    return progressColor
  }
  
  return (
    <div className="profile-main-container">
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
          <h6 className="mb-0 mt-0">
            <span className="ml-24">Division</span>
          </h6>
          <span className="text-secondary">{user.contestant_data?.division_data?.name}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
          <h6 className="mb-0 mt-0">
            <img height={25} width={25} src={levelUrl} alt='N/A'/>
            <span>Level</span>
          </h6>
          <span className="text-secondary">{user.contestant_data?.level_data?.description}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
          <h6 className="mb-0 mt-0">
            <span className="ml-24">Score</span>
          </h6>
          <span className="text-secondary">{user.contestant_data?.scores_data?.total_score}</span>
        </li>
      </ul>
      <ProgressBar bgColor={getColor()} className="progress--bar" completed={progressPercentage}/>
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
          <h6 className="mb-0 mt-0">
            <img height={25} width={25} src={nextLevelUrl} alt='N/A'/>
            <span>Next Level</span>
          </h6>
          <span className="text-secondary">
            {user.contestant_data?.next_level_data?.description}
          </span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
          <h6 className="mb-0 mt-0">
            <span className="ml-24">Points Needed to Advance</span>
          </h6>
          <span className="text-secondary">{pointsNeeded}</span>
        </li>
        <br/>
        {user.contestant_data?.scores_data?.sports.length ? (
          <>
            <h2>Sports Breakdown</h2>
            {user.contestant_data?.scores_data?.sports?.map((score: Score) => (
              <>
                {!sport || sport.name === score.name ? (
                  <li key={score.id}
                      className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="mb-0 mt-0">
                      <span className="ml-24">{score.name}</span>
                    </h6>
                    <span className="text-secondary">{score.points}</span>
                  </li>
                ) : null}
              </>
            ))}
          </>
        ) : null}
      </ul>
    </div>
  )
}

export default ProgressCard