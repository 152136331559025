import * as React from 'react';
import {
  HiOutlineQuestionMarkCircle,
  HiOutlineChevronRight,
  HiClipboardCheck,
} from 'react-icons/hi';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { Grid, TextField, IconButton, Loader, Message } from '../../components';
import { useAuth } from '../../state-management';
import { CURRENT_URL } from '../../utils';
import './styles.scss';

interface LoginProps {
  previous: {
    location: string;
  };
}

const Login: React.FC<LoginProps & RouteComponentProps> = ({
  history,
  previous,
}) => {
  const { state, login } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [msg, setMsg] = React.useState('');

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const onLogin = () => {
    setLoading(true);
    login(username, password).then((res: any) => {
      if (res?.status === 201) {
        setMsg('Successfully logged in!');
      }
      setLoading(false);
    });
  };

  let error = '';
  if (state.error?.includes('Unable to log in'))
    error = 'Incorrect username or password';
  else error = state.error;
  
  const loginForm = (
    <div className='lake-games--login'>
      <div className='lake-games--login-header'>Login</div>

      <Message severity={error ? 'error' : 'success'} message={error || msg} />
      <TextField
        value={username}
        id='username'
        label='Username'
        autoComplete='username'
        onChange={(e) => setUsername(e.target.value)}
        handleSubmit={onLogin}
      />
      <TextField
        value={password}
        id='password'
        label='Password'
        autoComplete='password'
        type='password'
        onChange={(e) => setPassword(e.target.value)}
        handleSubmit={onLogin}
      />
      <div className='lake-games--login-actions'>
        <Loader loading={loading} />
        <IconButton
          id='login-btn'
          tooltipTitle='Login'
          icon={HiOutlineChevronRight}
          onClick={onLogin}
          disabled={!username.length || !password.length}
        />
        <IconButton
          tooltipTitle='Forgot Password'
          icon={HiOutlineQuestionMarkCircle}
          onClick={() => history.push('/forgot')}
        />
        <IconButton
          tooltipTitle='Signup'
          icon={HiClipboardCheck}
          onClick={() => history.push('/signup')}
        />
        <a className='google-login' href={`${CURRENT_URL}accounts/google/login/?process=login`}>
          <GoogleButton />
        </a>
      </div>
    </div>
  );

  React.useEffect(() => {
    if (state.isAuthenticated) {
      history.push(previous?.location || '/contest');
    }
  }, [state.isAuthenticated, previous, history]);

  return (
    // @ts-ignore
    <Grid
      items={[
        {
          id: 'lake-games--login',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: loginForm,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Login);
