import { Dispatch } from 'react';
import { UploadsState } from './context';

export type UploadsAction =
  | { type: 'START_UPLOAD'; userId: number, uploadId: number, file: File, uploadUrl: string, chunkSize: number }
  | { type: 'SET_PROGRESS'; uploadId: number, progress: number }
  | { type: 'PAUSE_UPLOAD'; uploadId: number }
  | { type: 'RESUME_UPLOAD'; uploadId: number }
  | { type: 'ERROR_UPLOAD'; uploadId: number; error: string }
  | { type: 'FINISH_UPLOAD'; uploadId: number }
  | { type: 'CANCEL_UPLOAD'; uploadId: number };

export type UploadsDispatch = Dispatch<UploadsAction>;

export const reducer = (
  state: UploadsState,
  action: UploadsAction
): UploadsState => {
  switch (action.type) {
    
    case 'START_UPLOAD': {
      console.log('UPLOADING FILE', action.file)
      const upload = {
        userId: action.userId,
        file: action.file,
        chunkSize: action.chunkSize,
        uploadUrl: '',
        progress: 0,
        error: '',
      }
      return {
        ...state,
        error: null,
        uploads: { ...state.uploads, [action.uploadId]: upload },
      };
    }
    
    case 'SET_PROGRESS': {
      const upload = { ...state.uploads[action.uploadId] };
      upload.progress = action.progress;
      return { ...state, uploads: { ...state.uploads, [action.uploadId]: upload }};
    }
    
    case 'PAUSE_UPLOAD': {
      const upload = { ...state.uploads[action.uploadId] };
      return { ...state, uploads: { ...state.uploads, [action.uploadId]: upload }};
    }
    
    case 'RESUME_UPLOAD': {
      const upload = { ...state.uploads[action.uploadId] };
      return { ...state, uploads: { ...state.uploads, [action.uploadId]: upload }};
    }
    
    case 'ERROR_UPLOAD': {
      console.log('UPLOADING ERROR', action.error)
      const upload = { ...state.uploads[action.uploadId] };
      upload.error = action.error;
      return { ...state, uploads: { ...state.uploads, [action.uploadId]: upload }};
    }
    
    case 'FINISH_UPLOAD': {
      console.log('FINISHING UPLOAD', action.uploadId)
      const uploads = { ...state.uploads };
      return { ...state, uploads: { ...uploads }};
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
