import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getData } from '../../state-management/api';
import {
  User,
  Community,
  Lake,
  Sport,
  Post,
  Division,
  Lesson,
} from '../../state-management/types';
import { PostComponent, Loader } from '..';

import './styles.scss';

interface ScoreboardPostListProps {
  community?: Community | null
  lake?: Lake | null
  division?: Division | null
  sport?: Sport | null
}

const ScoreboardPostList: React.FC<ScoreboardPostListProps> = ({
  community,
  lake,
  division,
  sport,
}) => {
  const [posts, setPosts] = React.useState<Post[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  
  React.useEffect(() => {
    
    setLoading(true)
    let url = 'posts/?ordering=score&';

    if (community?.id) url += `community=${community?.id}&`;
    if (lake?.id) url += `lake=${lake?.id}&`;
    if (division?.id) url += `division=${division?.id}&`;
    if (sport?.id) url += `sport=${sport?.id}&`;

    getData(url)
      .then(({ results }) => {
        console.log('res:', results)
        setPosts(results);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  }, [community, lake, division, sport]);

  const loaderFragment = (
    <>
      <h3>Loading posts...</h3>
      <Loader loading={true} />
    </>
  )
  
  if (loading)
    return loaderFragment
  
  return (
    <>
      {posts?.length > 0 && (
        <>
          <h2>{division ? division.name : 'All Divisions'}</h2>
          {posts.map((post: Post) => (
            <>
              <PostComponent key={post.id} post={post} />
            </>
          ))}
        </>
      )}
    </>
  )
};

export default ScoreboardPostList;
