import * as React from 'react'
import {
  List,
  ListItem,
  Paper,
  Button,
} from '@material-ui/core'
import { useAuth } from '../../state-management'
import { User, Post, Lesson, Sport } from '../../state-management/types'
import {
  DialogBox,
  Message,
  Loader,
  TextField,
  SelectField,
} from '../../components'
import {
  VideoForm,
} from '..'
import './styles.scss'

interface LessonFormProps {
  user?: User
}

const LessonForm: React.FC<LessonFormProps> = ({ user }) => {
  const { state, getCoaches, getSports, uploadLesson } = useAuth()
  
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [refresh, setRefresh] = React.useState<boolean>(false)
  
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [msg, setMsg] = React.useState('')
  const [coach, setCoach] = React.useState<User>()
  const [sport, setSport] = React.useState<Sport>()
  const [duration, setDuration] = React.useState<number>(30)
  const [lessonUpload, setLessonUpload] = React.useState<Lesson>()
  const [postUploads, setPostUploads] = React.useState<Post[]>([])

  React.useEffect(() => {
    getSports()
  }, []) // eslint-disable-line

  React.useEffect(() => {
    getCoaches(state.community)
  }, [state.community]) // eslint-disable-line

  const handleSubmit = () => {
    console.log('user',user);
    setLoading(true)
    setError('')
    uploadLesson(sport, coach, user?.contestant_data).then((res: any) => {
      if (res?.status === 201) {
        setMsg('Successfully uploaded lesson');
        setLessonUpload(res?.data)
        setIsOpen(false)
      }
      setLoading(false);
    });
  }

  const clearForm = () => {
    setLessonUpload(undefined)
    setSport(undefined)
    setMsg('')
    setPostUploads([])
    setCoach(undefined)
    setDuration(30)
  }

  const appendPost = (upload: Post) => {
    setPostUploads(postUploads.concat(upload));
    setRefresh(!refresh);
  }
  
  const cleanFilename = (filename: string | null) => {
    let retVal = 'Unable to get file name'
    if (filename) {
      const filenameList = filename.split('/')
      if (filenameList.length)
        retVal = filenameList[filenameList.length - 1]
    }
    
    return retVal
  }

  const errorMsg = error || state.error

  const buttonItems = (
    <>
      <Message
        severity={errorMsg ? 'error' : 'success'}
        message={errorMsg || msg}
      />

      <Loader loading={loading} />
      <Button
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
        className='lg-video-form--btn'
        classes={{
          label: 'lg-video-form--btn-label',
        }}
      >
        Submit
      </Button>
      <Button
        variant='outlined'
        color='primary'
        onClick={clearForm}
        className='lg-video-form--btn'
        classes={{
          label: 'lg-video-form--btn-label',
        }}
      >
        Clear
      </Button>
    </>
  )


  const lessonFormFields = (
    <div className='lg-video-form'>
      <SelectField
        label='Coach'
        required
        value={coach}
        values={state.coaches || []}
        setValue={setCoach}
      />

      <SelectField
        label='Sport'
        required
        value={sport}
        values={state.sports || []}
        setValue={setSport}
      />

      <TextField
        id='duration'
        label='Duration'
        value={String(duration)}
        autoComplete='duration'
        onChange={(e) => setDuration(parseInt(e.target.value || '0', 10))}
        handleSubmit={handleSubmit}
      />
    </div>
  )
  
  console.log(lessonUpload)
  const lessonUploadComponent = (
    <p>
      <h3>Coach: {lessonUpload?.coach_data?.username}</h3>
    </p>
  )

  const postUploadList = (
    <List className='uploadList'>
      {postUploads?.map((upload: Post, key: number) => (
        <ListItem key={key}>
          <Paper elevation={3}>
            <div>Upload {key + 1}: {cleanFilename(upload.filename)}</div>
          </Paper>
        </ListItem>
      ))}
    </List>
  )

  return (
    <DialogBox isOpen={isOpen} handleCloseHook={clearForm} title='Upload Lesson'>
      <div className='lg-video-form'>
        {user?.contestant_data ? (
          <>
            {lessonUpload ? (
              <>
                {lessonUploadComponent}
                <VideoForm lesson={lessonUpload} open={true} allowComment={true} user={user} sportIn={sport} callback={appendPost}/>
              </>
            ) : lessonFormFields }
          </>
        ) : <strong>Must create contestant account first</strong>}
        
        {postUploadList}
        
        {!lessonUpload ? buttonItems : null}
      </div>
    </DialogBox>
  )
}

export default LessonForm
