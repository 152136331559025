import * as React from 'react'
import { useAlert } from 'react-alert';
import { Button, Checkbox } from '@material-ui/core';
import { useAuth } from '../../state-management'
import { User } from '../../state-management/types'
import {
  Message, 
  Loader, 
  DialogBox,
} from '../../components'
import './styles.scss'

interface UserPrivilegesProps {
  user: User
}

const UserPrivilegesForm: React.FC<UserPrivilegesProps> = ({ user }) => {
  const { state, updateContestant } = useAuth()
  
  const alert = useAlert()
  
  const [msg, setMsg] = React.useState<string>('')
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const [isPrivate, setIsPrivate] = React.useState<boolean>(user.contestant_data?.is_private ?? false)
  const [isCoach, setIsCoach] = React.useState<boolean>(user.contestant_data?.is_coach ?? false)
  const [isJudge, setIsJudge] = React.useState<boolean>(user.contestant_data?.is_judge ?? false)
  
  const handleSubmit = () => {
    setLoading(true)
    
    const contestant = user.contestant_data
    if (!contestant) {
      setError('No contestant data found')
      setLoading(false)
      return
    }
    contestant.is_private = isPrivate
    contestant.is_coach = isCoach
    contestant.is_judge = isJudge
    
    updateContestant(contestant, user)
    .then(() => {
        const msgText = 'Successfully updated contestant'
        setMsg(msgText)
        setError('')
        alert.show(msgText, { type: 'success' })
        setLoading(false)
      }
    )
  }
  
  const errorMsg = error || state.error

  return (
    <DialogBox title='Update Permissions'>
      <Loader loading={loading} />
      <Message
        severity={errorMsg ? 'error' : 'success'}
        message={errorMsg || msg}
      />
      <br />
      
      <div>
        Is Private: {isPrivate ? 'Yes' : 'No'}
        <Checkbox
          value={isPrivate}
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={(e) => {
            if (e.target.checked) setIsPrivate(true)
            else setIsPrivate(false)
          }}
        />
      </div>
      
      <div>
        Is Coach: {isCoach ? 'Yes' : 'No'}
        <Checkbox
          value={isCoach}
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={(e) => {
            if (e.target.checked) setIsCoach(true)
            else setIsCoach(false)
          }}
        />
      </div>
      
      <div>
        Is Judge: {isJudge ? 'Yes' : 'No'}
        <Checkbox
          value={isJudge}
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={(e) => {
            if (e.target.checked) setIsJudge(true)
            else setIsJudge(false)
          }}
        />
      </div>
      
      <br />
      
      <Button variant='outlined' color='primary' onClick={handleSubmit}>
        <div className='user-privileges-update'>Submit</div>
      </Button>
    </DialogBox>
  )
}

export default UserPrivilegesForm

