import * as React from 'react';
import { useAlert } from 'react-alert';
import { IconButton, Toolbar } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { LikeCategory } from '../../state-management/types';
import { useAuth, getData, postData, deleteData } from '../../state-management';
import { Shaka } from '..';
import './styles.scss';

interface LikesBarProps {
  likes: LikeCategory[] | null;
  postID: number;
}

const LikesBar: React.FC<LikesBarProps> = ({ likes, postID }) => {
  const { state } = useAuth();
  
  const alert = useAlert()
  
  const [likeData, setLikeData] = React.useState<LikeCategory[]>(likes || []);
  const [userLikeID, setUserLikeID] = React.useState('');

  React.useEffect(() => {
    if (state.logname)
      getData(`likes/?owner=${state.user?.id}&post=${postID}`).then((data) => {
        if (data) setUserLikeID(data.id);
      });
  }, [state.logname, state.user?.id, postID]);

  React.useEffect(() => {
    if (state.logname)
      getData(`likes/?post=${postID}`).then((data) => {
        if (data?.length) setLikeData(data);
        else setLikeData([]);
      });
  }, [state.logname, postID, userLikeID]);

  const performCreate = () => {
    const formData = new FormData();
    formData.append('post', String(postID));

    postData('likes/', formData).then((res) => setUserLikeID(res.data.id));
  };

  const performDelete = () => {
    deleteData(`likes/${userLikeID}`).then(() => setUserLikeID(''));
  };

  const handleClick = () => {
    console.log('userLikeID', userLikeID);

    if (state.logname)
      if (userLikeID) performDelete();
      else performCreate();
    else alert.show('Must be logged in', { type: 'error' })
  };

  return (
    <div className='lg-likes-bar'>
      <IconButton className='like-unlike' onClick={handleClick} aria-label='like-unlike'>
        <FavoriteIcon fontSize='large' color={userLikeID ? 'error' : 'disabled'} />
      </IconButton>

      <Toolbar disableGutters className='shaka-parent'>
        {likeData?.map((like: LikeCategory) => (
          <Shaka key={like.color} color={like.color} numLikes={like.likes} />
        ))}
      </Toolbar>
    </div>
  );
};

export default LikesBar;
