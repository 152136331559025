import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import { getData } from '../../state-management/api';
import {
  User,
  Sport,
  Lesson,
} from '../../state-management/types';
import { LessonComponent, Loader, PostList } from '..';

import './styles.scss';

interface LessonListProps {
  user?: User | null;
  sport?: Sport | null;
}

const LessonList: React.FC<LessonListProps> = ({
  user,
  sport,
}) => {
  const [lessons, setLessons] = React.useState<Lesson[]>([]);
  const [nextURL, setNextURL] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const getMoreData = () => {
    if (nextURL)
      setLoading(true)
      getData(nextURL).then((data) => {
        setNextURL(data.next);
        if (data?.results?.length)
          setLessons(lessons.concat(data.results));
        setLoading(false)
      });
  };

  React.useEffect(() => {
    let url = 'lessons/?';

    if (user) url += `customer=${user?.contestant_data?.id}&`;

    if (sport?.id) url += `sport=${sport?.id}&`;
  
    setLoading(true)
    getData(url)
      .then(({ results, next }) => {
        setLessons(results);
        setNextURL(next);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  }, [user, sport]);
 
  return (
    <>
     {console.log(lessons)}
      {lessons?.length ? (
        <InfiniteScroll
          dataLength={lessons?.length}
          next={getMoreData}
          loader={<Loader loading={true} />}
          hasMore={Boolean(nextURL)}
        >
          {lessons?.map((lesson: Lesson) => (
            <>
              {lesson?.posts_data?.length > 0 ? <LessonComponent key={lesson.id} lesson={lesson} /> : null}
            </>
          ))}
        </InfiniteScroll>
      ) : (
        <>
          {loading ? (
            <Loader loading={loading}/>
          ) : (
            <>No lessons found</>
          )}
        </>
      )}
    </>
  );
};

export default LessonList;
