import * as React from 'react';
import {RouteComponentProps, withRouter, Link} from 'react-router-dom';
import {Container} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import {publicBucketUrl} from '../../utils';
import './styles.scss';

const Welcome: React.FC<RouteComponentProps> = () => {
    const welcomeImages = ['tantrum.jpg'];

  const welcomeComponent = (
        <Container maxWidth="lg">
            <section className="hero">
                <Carousel className="carousel__div">
                    {welcomeImages?.map((item, i) => (
                        <div key={i}>
                            <img
                                key={i}
                                src={`${publicBucketUrl}/carousel/${item}`}
                                alt="Images of wakeboard tricks"
                                className='carousel__img'
                            />
                            <div className="hero__content">
                                <h1 className="hero__heading">Welcome to Lake Games!</h1>
                                <p className="hero__copy">
                                  For beginners to experts alike, Lake Games gives you a place to upload your water
                                  sports videos and find out what tricks to learn next! You can represent your lake
                                  and show off your skills, or find other riders in the area!
                                </p>
                                <div className="hero__btn-container btn-container">
                                    <Link to="/signup" className="btn btn--primary">
                                        Join the fun
                                    </Link>
                                    <Link to="/home" className="btn btn--ghost">
                                        Explore
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </section>
        </Container>
    );

    return <> {welcomeComponent} </>;
};

export default withRouter(Welcome);
