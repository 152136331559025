import { createContext } from 'react';
import { AuthDispatch } from './auth-reducer';
import {
  Division,
  User,
  Community,
  Lake,
  Sport,
  Rule,
  Lesson,
  Level,
  ReportOption,
  Challenge, Contest,
} from '../types';

export interface AuthState {
  logname: string | null;
  user: User | undefined;
  contest?: Contest;
  community: Community | null;
  lake: Lake | null;
  division: Division | null;
  sport: Sport | null;
  users: User[];
  rules: Rule[];
  lessons: Lesson[];
  levels: Level[];
  communities: Community[];
  lakes: Lake[];
  divisions: Division[];
  sports: Sport[];
  coaches: User[];
  reportOptions: ReportOption[];
  challenges: Challenge[];
  exp: string;
  error: any;
  loading: boolean;
  isAuthenticated: boolean;
}

export interface UserPayload {
  count: number;
  next: null;
  previous: null;
  results: User[];
}

export interface AuthContextState {
  state: AuthState;
  dispatch: AuthDispatch;
}

export const defaultState = (): AuthState => ({
  logname: null,
  user: undefined,
  community: null,
  lake: null,
  division: null,
  contest: undefined,
  sport: null,
  rules: [],
  users: [],
  levels: [],
  lessons: [],
  communities: [],
  lakes: [],
  sports: [],
  coaches: [],
  divisions: [],
  reportOptions: [],
  challenges: [],
  exp: '',
  error: '',
  loading: false,
  isAuthenticated: false,
});

export const AuthContext = createContext<AuthContextState>({
  state: defaultState(),
  dispatch: () => null,
});
