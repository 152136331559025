import * as React from 'react';
import {getData, useAuth} from '../../state-management';
import {Contestant, Division, User} from '../../state-management/types';
import {Avatar, ScoreboardCard} from '..';
import './styles.scss';


const ContestantsScoreboard: React.FC = () => {
  const { state } = useAuth();
  
  const getScore = (contestant: Contestant) => {
    const scores = contestant?.scores_data
    let score
  
    console.log('scores:', scores)
    
    if (state.sport)
      score = contestant?.scores_data?.sports[state.sport.id + 1]?.points
    else
      score = scores?.total_score
  
    console.log('score:', score)
    return score ?? 0
  }
  
  const getRows = async (division?: Division) => {
    let url = 'contestants/?ordering=score&'
    if (division) url += `division=${division.id}&`
    if (state.sport) url += `sport=${state.sport.id}&`
    if (state.community) url += `community=${state.community.id}&`
  
    const res = await getData(url)
    const rows = res.results.filter((contestant: Contestant) => getScore(contestant) > 0).map((contestant: Contestant) => (
      {
        id: contestant.id,
        name: contestant.username,
        avatar: contestant.profile_data?.filename,
        link: `/user/${contestant?.username}`,
        score: getScore(contestant)
      }
    )) ?? []
    return rows
  }
  
  return (
    <>
      <ScoreboardCard sport={state.sport} getRows={() => getRows()}/>
      {state.divisions?.map((division: Division) => (
        <div key={division.id}>
          <ScoreboardCard
            sport={state.sport}
            division={division}
            getRows={() => getRows(division)}
          />
        </div>
      ))}
    </>
  );
};

export default ContestantsScoreboard;
