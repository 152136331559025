import * as React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../state-management/types';
import { Avatar } from '..';
import './styles.scss';

interface UserListProps {
  users: User[];
}

const UserList: React.FC<UserListProps> = ({ users }) => {
  return (
    <>
     {users?.length ? (
        <div className="row ng-scope">
            
            <div className="col-md-9 col-md-pull-3">
            {users?.map((user: User) => (
                <section className="search-result-item">
                    
                    <Link to={`/user/${user?.username}`} className="avatar-div"> 
                    <div className='ava-img'><Avatar user={user} /></div>
                    <div className='ava-text'>{user.username}</div>
                    </Link>
                </section>
            ))}
            </div>
        </div>
        ) : (
          <div>No users found!</div>
        )}
    </>
  );
};

export default UserList;
