import * as React from 'react'
import {
  List,
  ListItem,
  Paper,
} from '@material-ui/core'
import ProgressBar from '@ramonak/react-progress-bar';

import {useAuth, useUploads} from '../../state-management'
import {User, Sport, ContestantEntry} from '../../state-management/types'
import {
  DialogBox,
  Message,
} from '../../components'
import {
  VideoForm,
} from '..'
import './styles.scss'
import {Upload} from '../../state-management/uploads/context';

interface ContestUploadsFormProps {
  contestantEntry?: ContestantEntry
  sportIn?: Sport
  removeButton?: boolean
}

const ContestUploadsForm: React.FC<ContestUploadsFormProps> = ({ contestantEntry, sportIn, removeButton }) => {
  const { state, getUserById } = useAuth()
  const uploads = useUploads()
  
  const [user, setUser] = React.useState<User>()
  const [videoFormOpen, setVideoFormOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (contestantEntry?.user_id) {
      getUserById(contestantEntry?.user_id).then((user) => {
        setUser(user)
      })
    }
  }, []) // eslint-disable-line

  const cleanFilename = (filename: string | null) => {
    let retVal = 'Unable to get file name'
    if (filename) {
      const filenameList = filename.split('/')
      if (filenameList.length)
        retVal = filenameList[filenameList.length - 1]
    }
    
    return retVal
  }
  
  const errorMsg = state.error
  
  const getContestantUploads = () => {
    const uploadsList = Object.values(uploads.state.uploads)
    const filteredUploads = uploadsList.filter((upload: Upload) => upload.userId === contestantEntry?.user_id)
    return filteredUploads
  }
  
  const uploadCallback = () => {
    setVideoFormOpen(false)
  }

  const postUploadList = (
    <List className='uploadList'>
      {getContestantUploads()?.map((upload: Upload, i: number) => (
        <ListItem key={upload.uploadUrl}>
          <Paper elevation={3}>
            <div>Upload {i + 1}: {cleanFilename(upload.file?.name)}</div>
            <ProgressBar className='contest-progress' bgColor='limegreen' completed={upload.progress}/>
          </Paper>
        </ListItem>
      ))}
    </List>
  )

  return (
    <DialogBox removeButton={removeButton} title='Upload Contest Videos'>
      <div className='lg-video-form'>
        {contestantEntry ? (
          <VideoForm
            closeAfterCreate={true}
            contestantEntry={contestantEntry}
            open={videoFormOpen}
            user={user}
            sportIn={sportIn}
            callback={uploadCallback}
          />
        ) : <strong>Must enter for the contest first</strong>}
        
        {postUploadList}
        
        <Message
          severity='error'
          message={errorMsg}
        />
      </div>
    </DialogBox>
  )
}

export default ContestUploadsForm
