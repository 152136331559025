import * as React from 'react'
import {Link, RouteComponentProps, withRouter} from 'react-router-dom'
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {contestantDisplayName, isPrivileged} from '../../utils';
import { useAuth } from '../../state-management'
import {Contest, ContestantEntry} from '../../state-management/types';
import {
  Grid,
  Loader,
  Medal, SelectField,
} from '../../components'
import {
  EditContestantEntryDivisionForm,
  EditContestantEntryPlaceForm,
  ContestEntryForm,
  ContestUploadsForm,
} from '../../forms'
import './styles.scss'

interface ContestProps {
  previous: {
    location: string
  }
}

const ContestPage: React.FC<ContestProps & RouteComponentProps> = () => {
  const { state, getContests } = useAuth()
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const [contests, setContests] = React.useState<Contest[]>([]);
  const [selectedContest, setSelectedContest] = React.useState<Contest>();
  const [selectedContestant, setSelectedContestant] = React.useState<ContestantEntry>();
  
  React.useEffect(() => {
    setLoading(true)
    getContests().then((contests: Contest[]) => {
      setLoading(false)
      setContests(contests)
      if (contests?.length)
        setSelectedContest(contests[0])
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>, contestant: ContestantEntry) => {
    setSelectedContestant(contestant);
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const getWakeboarding = () => {
    let sport
    state.sports.forEach((s) => {
      // TODO: improve hardcoding: use sport field on contest
      if (s.id === 1)
        sport = s
    })
    
    return sport
  }
  
  
  const selectedContestIsOver = selectedContest?.end_time && new Date(selectedContest?.end_time) > new Date()
  
  const contestComponent = (
    <>
      <h2>Welcome to the Payton Power Classic!</h2>
      
      <SelectField
        value={selectedContest}
        values={contests}
        setValue={setSelectedContest}
        label='Select Contest'
      />
      
      <br/>
      <br/>
      
      {selectedContest && (isPrivileged(state.user) || selectedContestIsOver) && (
        <ContestEntryForm contest={selectedContest} />
      )}
      
      <br/>
      
      <Loader loading={loading}/>
      
      {selectedContest?.divisions?.map((division) => (
        <div key={division?.id}>
          {division?.contestant_entries?.length > 0 && (
            <div>
              <h3>{division?.name}</h3>
              <table className="table table-bordered scores-table">
                <tbody className="scores-row">
                {division?.contestant_entries.sort((a, b) => {
                  if (!a?.place)
                    return 1
                  if (!b?.place)
                    return -1
                  return a?.place - b?.place
                })?.map((c) => (
                  <tr key={c?.id} className="scores-row">
                    <td className="scores-count">
                      <Medal place={c?.place_str} />
                      
                      <Link style={{textDecoration: 'none', color: 'inherit'}} to={`/user/${c?.username}`}>
                        {`${contestantDisplayName(c)}`}
                      </Link>
                      
                      {isPrivileged(state?.user) ? (
                      <>
                        <IconButton aria-label='extra' onClick={(e) => handleOptionsClick(e, c)}>
                          <MoreVertIcon />
                        </IconButton>
                        
                        <Menu
                          id='post-menu'
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          classes={{
                            paper: 'lg-post-menu--paper',
                            list: 'lg-post-menu--list',
                          }}
                        >
                          
                          <MenuItem button className='lg-post-menu--item btn1'>
                            <EditContestantEntryDivisionForm contestantEntry={selectedContestant}/>
                          </MenuItem>
                          
                          <MenuItem button className='lg-post-menu--item btn1'>
                            <ContestUploadsForm
                              contestantEntry={selectedContestant}
                              sportIn={getWakeboarding()}
                              removeButton={true}
                            />
                          </MenuItem>
                          
                          <MenuItem button className='lg-post-menu--item btn1'>
                            <EditContestantEntryPlaceForm contestantEntry={selectedContestant}/>
                          </MenuItem>
                        
                        </Menu>
                      </>
                      ) : null}
                    </td>
                    
                    
                    {/*<Carousel key={c?.id} className="carousel__div" autoPlay={false}>*/}
                    {/*  {c?.posts?.map((post) => (*/}
                    {/*    <Card>*/}
                    {/*      <CardMedia*/}
                    {/*        component='video'*/}
                    {/*        controls*/}
                    {/*        autoPlay={false}*/}
                    {/*        src={String(post.filename)}*/}
                    {/*      />*/}
                    {/*    </Card>*/}
                    {/*  ))}*/}
                    {/*</Carousel>*/}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </>
  )
  
  return (
    // @ts-ignore
    <Grid
      items={[
      {
        id: 'lake-games--contests',
        cols: { xs: 12, sm: 8, md: 6, lg: 6 },
        content: contestComponent,
        rows: 1,
      },
    ]}
    />
  )
}

export default withRouter(ContestPage)
