import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Paper, Toolbar, Tabs, Tab } from '@material-ui/core';
import {
  Grid,
  LakesScoreboard,
  FilterDialog,
  ContestantsScoreboard,
  PostsScoreboard,
} from '../../components';
import './styles.scss';

const Scoreboard: React.FC<RouteComponentProps> = () => {
  const [value, setValue] = React.useState(0);

  let component = null;

  switch (value) {
    case 0:
      component = (
        <LakesScoreboard />
      );
      break;

    case 1:
      component = (
        <ContestantsScoreboard/>
      );
      break;

    case 2:
      component = (
        <PostsScoreboard/>
      );
      break;

    default:
      console.log('Error in scoreboard tab value');
      break;
  }

  const scoreboardComponent = (
    <div className='lake-games--scoreboard'>
      <div className='lake-games--scoreboard-header'>Leaderboards</div>
      <FilterDialog lake division sport />
      <Toolbar className="tool__bar">
        <Paper>
          <Tabs
            value={value}
            indicatorColor='primary'
            textColor='primary'
            onChange={(e, newVal) => setValue(newVal)}
            aria-label='user-tabs'
          >
            <Tab label='Lakes' />
            <Tab label='Athletes' />
            <Tab label='Posts' />
          </Tabs>
        </Paper>
      </Toolbar>
      {component}
    </div>
  );

  return (
    // @ts-ignore
    <Grid
      items={[
        {
          id: 'lake-games--scoreboard',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: scoreboardComponent,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Scoreboard);
